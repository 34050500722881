import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import { Alert, Badge, Button, Card, CardBody, Input, Label } from "reactstrap";

import TableContainer from "../../../components/Common/TableContainer";
import {
  getApiData,
  postApiData,
  putApiData,
} from "../../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddRateSheetToggle from "./AddRateSheet";
import ViewRateSheetToggle from "./ViewRateSheet";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import EditDraftRateSheetToggle from "./EditDraftRateSheet";

const RateSheetTable = (props) => {
  const [addRateSheetModal, setAddRateSheetModal] = useState(false);
  const [viewRateSheetModal, setViewRateSheetModal] = useState(false);
  const [editDraftRateSheetModal, setEditDraftRateSheetModal] = useState(false);
  const [rateMasterData, setRateMasterData] = useState([]);
  const [sendRateSheet, setSendRateSheet] = useState([]);
  const [rateSheetID, setRateSheetID] = useState("");

  //Toggle methods for Modal
  const addRateSheetModalToggle = () =>
    setAddRateSheetModal(!addRateSheetModal);

  const viewRateSheetModalToggle = () => {
    setViewRateSheetModal(!viewRateSheetModal);
  };

  const EditDraftRateSheetModalToggle = () => {
    setEditDraftRateSheetModal(!editDraftRateSheetModal);
  };

  //View Rate Sheet by Sheet ID
  const viewRateSheet = async (cellProps, name) => {
    const { rateSheetId } = cellProps;
    setRateSheetID(rateSheetId);
    try {
      const response = await getApiData(
        `api/RateHistory/GetRateHistorySheet?RateSheetId=${rateSheetId}`
      );
      //console.log(response);

      const rateTypeNames = {
        1: "Buying",
        2: "Selling",
        3: "Transfer",
      };
      const rateHistories = response.map((item, key) => ({
        index: key + 1,
        rateHistoryId: item.rateHistoryId,
        rateSheetId: item.rateSheetId,
        branchId: item.branchId,
        rateType: rateTypeNames[item.rateType],
        currencyCode: item.currencyCode,
        value: item.value,
      }));
      setSendRateSheet(rateHistories);
      if (name === "editDraft") {
        EditDraftRateSheetModalToggle();
      } else if (name === "viewSheet") {
        viewRateSheetModalToggle();
      }
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const rate = async () => {
    try {
      const response = await getApiData(`api/RateMaster/GetAllRateSheets`);
      //console.log(response);

      const status = {
        1: "Active",
        2: "Draft",
        3: "Elapsed",
      };

      const rateTypeNames = {
        1: "Buying",
        2: "Selling",
        3: "Transfer",
      };

      const branch = {
        1: "All",
      };
      const mappedResponse = response.map((item, index) => ({
        rateSheetId: item.rateSheetId,
        branch: branch[item.branchID],
        startTime: item.startTime,
        endTime: item.endTime,
        status: status[item.status],
      }));

      setRateMasterData(mappedResponse);
      //console.log(mappedResponse);
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };
  //Get All Rate Sheets
  useEffect(() => {
    rate();
  }, []);

  //Elapse the sheet
  const elapseCurrentRateSheet = async (cellProps) => {
    const { rateSheetId } = cellProps;
    Swal.fire({
      title: "Elapse",
      text: "Are you sure to elapse the current rate sheet?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes, Elapse it",
      confirmButtonColor: "#f46a6a",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await putApiData(
            `api/RateMaster/UpdateRateSheetStatusToElapsed?RateSheetID=${rateSheetId}`
          );
          //console.log(response);

          if (response.success === true) {
            rate();
            toast.success("Success: Current Sheet has set to elapsed", {
              position: "top-right",
              autoClose: 3000,
              onClose: () => {},
            });
          } else {
            toast.error(response.message, {
              position: "top-right",
              autoClose: 3000,
            });
          }
        } catch (error) {
          toast.error(error, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      }
    });
  };

  //Columns for the rate sheet table
  const columns = useMemo(
    () => [
      {
        Header: "RateSheetID",
        accessor: "rateSheetId",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Branch",
        accessor: "branch",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Start time",
        accessor: "startTime",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "End Time",
        accessor: "endTime",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        show: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                (cellProps.value === "Active"
                  ? "success"
                  : "danger" && cellProps.value === "Draft"
                  ? "primary"
                  : "danger")
              }
            >
              {cellProps.value}
            </Badge>
          );
        },
      },

      {
        Header: "Actions",
        disableFilters: true,
        accessor: "actions",
        Cell: (cellProps) => {
          const { status } = cellProps.row.original;
          return (
            <>
              {status === "Draft" && ( // Render the "Activate" button only if status is "Draft"
                <Button
                  title="Edit Draft"
                  name="editDraft"
                  type="button"
                  color="success"
                  className="btn-md btn-rounded"
                  style={{ marginRight: "5px" }}
                  onClick={(e) =>
                    viewRateSheet(cellProps.row.original, "editDraft")
                  }
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </Button>
              )}
              {status === "Active" || status === "Draft" ? (
                <Button
                  type="button"
                  name="elapse"
                  color="danger"
                  className="btn-md btn-rounded"
                  style={{
                    marginRight: "5px",
                  }}
                  onClick={() => elapseCurrentRateSheet(cellProps.row.original)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              ) : null}
              <Button
                type="button"
                color="primary"
                name="viewSheet"
                className="btn-md btn-rounded"
                style={{ marginRight: "5px" }}
                onClick={(e) =>
                  viewRateSheet(cellProps.row.original, "viewSheet")
                }
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
              {status === "Active" && (
                <Button
                  type="button"
                  color="secondary"
                  className="btn-md btn-rounded"
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <AddRateSheetToggle
        isOpen={addRateSheetModal}
        toggle={addRateSheetModalToggle}
        rateSheet={rate}
      />
      <ViewRateSheetToggle
        sendRateSheet={sendRateSheet}
        isOpen={viewRateSheetModal}
        toggle={viewRateSheetModalToggle}
      />
      <EditDraftRateSheetToggle
        sendRateSheet={sendRateSheet}
        rateMasterData={rateMasterData}
        isOpen={editDraftRateSheetModal}
        toggle={EditDraftRateSheetModalToggle}
        rateSheetID={rateSheetID}
        rateSheet={rate}
      />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Rate Sheet</div>
          <div className="mb-3">
            {/* <div className="form-check">
              <Label className="form-check-Label" htmlFor="formrow-customCheck">
                All currency rates are converted from 1 USD (i.e 1 USD = 83.09
                INR)
              </Label>
            </div>*/}
          </div>
          <div className="text-sm-end">
            <Button
              type="button"
              color="primary"
              className="btn mb-2 me-2"
              onClick={addRateSheetModalToggle}
            >
              <i className="mdi mdi-plus-circle-outline me-1" />
              Add New Sheet
            </Button>
          </div>
          {/* <Alert color="info" isOpen={visible} toggle={onDismiss}>
            I am an alert and I can be dismissed!
          </Alert> */}
          <TableContainer
            columns={columns}
            data={rateMasterData}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={15}
            isPageSelect={false}
            customId={"status"}
            desc={false}
          />
        </CardBody>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
};

RateSheetTable.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default RateSheetTable;
