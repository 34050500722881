import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../Common/withRouter";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

const Navbar = (props) => {
  const location = useLocation();

  const [dashboard, setdashboard] = useState(false);
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [ecommerce, setecommerce] = useState(false);
  const [crypto, setcrypto] = useState(false);
  const [project, setproject] = useState(false);
  const [task, settask] = useState(false);
  const [contact, setcontact] = useState(false);
  const [blog, setBlog] = useState(false);
  const [job, setJob] = useState(false);
  const [candidate, setCandidate] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);
  const [invoice, setinvoice] = useState(false);
  const [auth, setauth] = useState(false);
  const [utility, setutility] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  //redirecting the dashboard according to the current dashboard
  var dashboardLocation;

  const userType = JSON.parse(sessionStorage.getItem("authUser"))["userType"];
  if (userType === "systemuser") {
    dashboardLocation = "/admindashboard";
  } else {
    dashboardLocation = "/dashboard";
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {/*Dashboard */}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={(e) => {
                      // e.preventDefault();
                      // setdashboard(!dashboard);
                    }}
                    to={dashboardLocation}
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Dashboard")} {props.menuOpen}
                  </Link>
                </li>

                {/*Features */}
                {userType === "systemuser" ? (
                  <>
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/manageuser"
                    >
                      <i className="bx bx-user-circle me-2"></i>
                      {props.t("Manage User")}
                    </Link>
                    <Link
                      to="/accounts"
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-list-ul me-2"></i>
                      {props.t("Accounts")}
                    </Link>
                    <Link
                      to="/managerates"
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-money me-2"></i>

                      {props.t("Manage Rate")}
                    </Link>
                    <Link
                      to="/transaction"
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-wallet me-2"></i>
                      {props.t("Transaction")}
                    </Link>
                  </>
                ) : (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setapp(!app);
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Features")} <div className="arrow-down"></div>
                    </Link>
                    <div className={classname("dropdown-menu", { show: app })}>
                      <Link to="/managerates" className="dropdown-item">
                        {props.t("Manage Rate")}
                      </Link>
                      <Link to="/transaction" className="dropdown-item">
                        {props.t("Transaction")}
                      </Link>
                    </div>
                  </li>
                )}

                {userType === "systemuser" ? null : (
                  //Contact Us
                  <>
                    <li className="nav-item dropdown">
                      <Link
                        to="/contact"
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={(e) => {
                          // e.preventDefault();
                          // setcomponent(!component);
                        }}
                      >
                        <i className="bx bx-collection me-2"></i>
                        {props.t("Contact Us")}
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/faqs"
                        onClick={(e) => {
                          // e.preventDefault();
                          // setextra(!extra);
                        }}
                      >
                        <i className="bx bx-file me-2"></i>
                        {props.t("FAQs")}
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
