const currencies = [
  { key: "AED", name: "United Arab Emirates Dirham (AED)" },
  { key: "AFN", name: "Afghan Afghani (AFN)" },
  { key: "ALL", name: "Albanian Lek (ALL)" },
  { key: "AMD", name: "Armenian Dram (AMD)" },
  { key: "ANG", name: "Netherlands Antillean Guilder (ANG)" },
  { key: "AOA", name: "Angolan Kwanza (AOA)" },
  { key: "ARS", name: "Argentine Peso (ARS)" },
  { key: "AUD", name: "Australian Dollar (AUD)" },
  { key: "AWG", name: "Aruban Florin (AWG)" },
  { key: "AZN", name: "Azerbaijani Manat (AZN)" },
  { key: "BAM", name: "Bosnia-Herzegovina Convertible Mark (BAM)" },
  { key: "BBD", name: "Barbadian Dollar (BBD)" },
  { key: "BDT", name: "Bangladeshi Taka (BDT)" },
  { key: "BGN", name: "Bulgarian Lev (BGN)" },
  { key: "BHD", name: "Bahraini Dinar (BHD)" },
  { key: "BIF", name: "Burundian Franc (BIF)" },
  { key: "BMD", name: "Bermudian Dollar (BMD)" },
  { key: "BND", name: "Brunei Dollar (BND)" },
  { key: "BOB", name: "Bolivian Boliviano (BOB)" },
  { key: "BRL", name: "Brazilian Real (BRL)" },
  { key: "BSD", name: "Bahamian Dollar (BSD)" },
  { key: "BTN", name: "Bhutanese Ngultrum (BTN)" },
  { key: "BWP", name: "Botswana Pula (BWP)" },
  { key: "BYN", name: "Belarusian Ruble (BYN)" },
  { key: "BZD", name: "Belize Dollar (BZD)" },
  { key: "CAD", name: "Canadian Dollar (CAD)" },
  { key: "CDF", name: "Congolese Franc (CDF)" },
  { key: "CHF", name: "Swiss Franc (CHF)" },
  { key: "CLP", name: "Chilean Peso (CLP)" },
  { key: "CNY", name: "Chinese Yuan (CNY)" },
  { key: "COP", name: "Colombian Peso (COP)" },
  { key: "CRC", name: "Costa Rican Colón (CRC)" },
  { key: "CUC", name: "Cuban Convertible Peso (CUC)" },
  { key: "CUP", name: "Cuban Peso (CUP)" },
  { key: "CVE", name: "Cape Verdean Escudo (CVE)" },
  { key: "CZK", name: "Czech Koruna (CZK)" },
  { key: "DJF", name: "Djiboutian Franc (DJF)" },
  { key: "DKK", name: "Danish Krone (DKK)" },
  { key: "DOP", name: "Dominican Peso (DOP)" },
  { key: "DZD", name: "Algerian Dinar (DZD)" },
  { key: "EGP", name: "Egyptian Pound (EGP)" },
  { key: "ERN", name: "Eritrean Nakfa (ERN)" },
  { key: "ETB", name: "Ethiopian Birr (ETB)" },
  { key: "EUR", name: "Euro (EUR)" },
  { key: "FJD", name: "Fijian Dollar (FJD)" },
  { key: "FKP", name: "Falkland Islands Pound (FKP)" },
  { key: "GBP", name: "British Pound Sterling (GBP)" },
  { key: "GEL", name: "Georgian Lari (GEL)" },
  { key: "GGP", name: "Guernsey Pound (GGP)" },
  { key: "GHS", name: "Ghanaian Cedi (GHS)" },
  { key: "GIP", name: "Gibraltar Pound (GIP)" },
  { key: "GMD", name: "Gambian Dalasi (GMD)" },
  { key: "GNF", name: "Guinean Franc (GNF)" },
  { key: "GTQ", name: "Guatemalan Quetzal (GTQ)" },
  { key: "GYD", name: "Guyanese Dollar (GYD)" },
  { key: "HKD", name: "Hong Kong Dollar (HKD)" },
  { key: "HNL", name: "Honduran Lempira (HNL)" },
  { key: "HRK", name: "Croatian Kuna (HRK)" },
  { key: "HTG", name: "Haitian Gourde (HTG)" },
  { key: "HUF", name: "Hungarian Forint (HUF)" },
  { key: "IDR", name: "Indonesian Rupiah (IDR)" },
  { key: "ILS", name: "Israeli New Shekel (ILS)" },
  { key: "IMP", name: "Manx Pound (IMP)" },
  { key: "INR", name: "Indian Rupee (INR)" },
  { key: "IQD", name: "Iraqi Dinar (IQD)" },
  { key: "IRR", name: "Iranian Rial (IRR)" },
  { key: "ISK", name: "Icelandic Króna (ISK)" },
  { key: "JEP", name: "Jersey Pound (JEP)" },
  { key: "JMD", name: "Jamaican Dollar (JMD)" },
  { key: "JOD", name: "Jordanian Dinar (JOD)" },
  { key: "JPY", name: "Japanese Yen (JPY)" },
  { key: "KES", name: "Kenyan Shilling (KES)" },
  { key: "KGS", name: "Kyrgyzstani Som (KGS)" },
  { key: "KHR", name: "Cambodian Riel (KHR)" },
  { key: "KMF", name: "Comorian Franc (KMF)" },
  { key: "KPW", name: "North Korean Won (KPW)" },
  { key: "KRW", name: "South Korean Won (KRW)" },
  { key: "KWD", name: "Kuwaiti Dinar (KWD)" },
  { key: "KYD", name: "Cayman Islands Dollar (KYD)" },
  { key: "KZT", name: "Kazakhstani Tenge (KZT)" },
  { key: "LAK", name: "Laotian Kip (LAK)" },
  { key: "LBP", name: "Lebanese Pound (LBP)" },
  { key: "LKR", name: "Sri Lankan Rupee (LKR)" },
  { key: "LRD", name: "Liberian Dollar (LRD)" },
  { key: "LSL", name: "Lesotho Loti (LSL)" },
  { key: "LYD", name: "Libyan Dinar (LYD)" },
  { key: "MAD", name: "Moroccan Dirham (MAD)" },
  { key: "MDL", name: "Moldovan Leu (MDL)" },
  { key: "MGA", name: "Malagasy Ariary (MGA)" },
  { key: "MKD", name: "Macedonian Denar (MKD)" },
  { key: "MMK", name: "Myanmar Kyat (MMK)" },
  { key: "MNT", name: "Mongolian Tögrög (MNT)" },
  { key: "MOP", name: "Macanese Pataca (MOP)" },
  { key: "MRO", name: "Mauritanian Ouguiya (MRO)" },
  { key: "MRU", name: "Mauritanian Ouguiya (MRU)" },
  { key: "MUR", name: "Mauritian Rupee (MUR)" },
  { key: "MVR", name: "Maldivian Rufiyaa (MVR)" },
  { key: "MWK", name: "Malawian Kwacha (MWK)" },
  { key: "MXN", name: "Mexican Peso (MXN)" },
  { key: "MYR", name: "Malaysian Ringgit (MYR)" },
  { key: "MZN", name: "Mozambican Metical (MZN)" },
  { key: "NAD", name: "Namibian Dollar (NAD)" },
  { key: "NGN", name: "Nigerian Naira (NGN)" },
  { key: "NIO", name: "Nicaraguan Córdoba (NIO)" },
  { key: "NOK", name: "Norwegian Krone (NOK)" },
  { key: "NPR", name: "Nepalese Rupee (NPR)" },
  { key: "NZD", name: "New Zealand Dollar (NZD)" },
  { key: "OMR", name: "Omani Rial (OMR)" },
  { key: "PAB", name: "Panamanian Balboa (PAB)" },
  { key: "PEN", name: "Peruvian Sol (PEN)" },
  { key: "PGK", name: "Papua New Guinean Kina (PGK)" },
  { key: "PHP", name: "Philippine Peso (PHP)" },
  { key: "PKR", name: "Pakistani Rupee (PKR)" },
  { key: "PLN", name: "Polish Złoty (PLN)" },
  { key: "PYG", name: "Paraguayan Guarani (PYG)" },
  { key: "QAR", name: "Qatari Riyal (QAR)" },
  { key: "RON", name: "Romanian Leu (RON)" },
  { key: "RSD", name: "Serbian Dinar (RSD)" },
  { key: "RUB", name: "Russian Ruble (RUB)" },
  { key: "RWF", name: "Rwandan Franc (RWF)" },
  { key: "SAR", name: "Saudi Riyal (SAR)" },
  { key: "SBD", name: "Solomon Islands Dollar (SBD)" },
  { key: "SCR", name: "Seychellois Rupee (SCR)" },
  { key: "SDG", name: "Sudanese Pound (SDG)" },
  { key: "SEK", name: "Swedish Krona (SEK)" },
  { key: "SGD", name: "Singapore Dollar (SGD)" },
  { key: "SHP", name: "Saint Helena Pound (SHP)" },
  { key: "SLL", name: "Sierra Leonean Leone (SLL)" },
  { key: "SOS", name: "Somali Shilling (SOS)" },
  { key: "SRD", name: "Surinamese Dollar (SRD)" },
  { key: "SSP", name: "South Sudanese Pound (SSP)" },
  { key: "STD", name: "São Tomé and Príncipe Dobra (STD)" },
  { key: "STN", name: "São Tomé and Príncipe Dobra (STN)" },
  { key: "SVC", name: "Salvadoran Colón (SVC)" },
  { key: "SYP", name: "Syrian Pound (SYP)" },
  { key: "SZL", name: "Swazi Lilangeni (SZL)" },
  { key: "THB", name: "Thai Baht (THB)" },
  { key: "TJS", name: "Tajikistani Somoni (TJS)" },
  { key: "TMT", name: "Turkmenistan Manat (TMT)" },
  { key: "TND", name: "Tunisian Dinar (TND)" },
  { key: "TOP", name: "Tongan Paʻanga (TOP)" },
  { key: "TRY", name: "Turkish Lira (TRY)" },
  { key: "TTD", name: "Trinidad and Tobago Dollar (TTD)" },
  { key: "TWD", name: "New Taiwan Dollar (TWD)" },
  { key: "TZS", name: "Tanzanian Shilling (TZS)" },
  { key: "UAH", name: "Ukrainian Hryvnia (UAH)" },
  { key: "UGX", name: "Ugandan Shilling (UGX)" },
  { key: "USD", name: "United States Dollar (USD)" },
  { key: "UYU", name: "Uruguayan Peso (UYU)" },
  { key: "UZS", name: "Uzbekistani Som (UZS)" },
  { key: "VES", name: "Venezuelan Bolívar Soberano (VES)" },
  { key: "VND", name: "Vietnamese Đồng (VND)" },
  { key: "VUV", name: "Vanuatu Vatu (VUV)" },
  { key: "WST", name: "Samoan Tālā (WST)" },
  { key: "XAF", name: "Central African CFA Franc (XAF)" },
  { key: "XCD", name: "East Caribbean Dollar (XCD)" },
  { key: "XDR", name: "Special Drawing Rights (XDR)" },
  { key: "XOF", name: "West African CFA Franc (XOF)" },
  { key: "XPF", name: "CFP Franc (XPF)" },
  { key: "YER", name: "Yemeni Rial (YER)" },
  { key: "ZAR", name: "South African Rand (ZAR)" },
  { key: "ZMW", name: "Zambian Kwacha (ZMW)" },
  { key: "ZWL", name: "Zimbabwean Dollar (ZWL)" },
];

export default currencies;
