import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// actions
import { loginUser } from "../../store/actions";

// import images
import profile from "../../../images/profile-img.png";
import logo from "../../../images/logo.svg";
import { createSelector } from "reselect";
import { postApiData } from "../../helpers/axiosHelper";

const SystemUserLogin = (props) => {
  //meta title
  document.title = "Crescent Exchange | Admin Login";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [selectUserType, setSelectedUserType] = useState("");

  const handleClick = () => {
    setUsername("admin_user");
    setPassword("admin123!");
  };
  const [userLogin, setUserLogin] = useState({
    username: username,
    password: "",
    token: "",
    userType: "",
  });

  const registerSelector = createSelector(
    (state) => state.Account,
    (account) => ({
      user: account.user,
    })
  );
  const { user } = useSelector(registerSelector);

  useEffect(() => {
    if (user) {
      setUserLogin({
        username: user.username || username,
        password: user.password || password,
      });
    }
  }, [user, username, password]);

  //for radio button selection
  const handleOptionChange = (event) => {
    setSelectedUserType(event.target.value);
  };

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: userLogin.username || username,
      password: userLogin.password || password,
      token: userLogin.token,
      userType: userLogin.userType,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    // onSubmit: (values) => {
    //   dispatch(loginUser(values, props.router.navigate));
    // }

    onSubmit: async (values) => {
      const response = await postApiData(
        "api/Login/Login",
        JSON.stringify(values)
      );

      if (response.success === true) {
        if (response.data.token) {
          values.userType = response.data.userType;
          values.token = response.data.token;
          setToken(response.data.token);
          const logged_user = {
            login: true,
            uid: response.data.uid,
            email: response.data.username,
            token: response.data.token,
            userType: response.data.userType,
          };
          sessionStorage.setItem("authUser", JSON.stringify(logged_user));
          if (response.data.userType === "systemuser") {
            toast.success("Admin Login Successful", {
              position: "top-right",
              autoClose: 3000,
              onClose: () => {
                navigate("/admindashboard");
              },
            });
          }
        }
        //alert("logged in ");
        //dispatch(loginUser(values, props.router.navigate));
      } else {
        alert("Invalid Credentials. Please try again");
      }
    },
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/home" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome System User !</h5>
                        <p>Sign in to Crescent Exchange Admin portal.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ToastContainer closeButton={false} limit={1} />

                      <div className="mb-3">
                        <Label className="form-label">Username</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter username"
                          //type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      {/* <div className="mb-3">
                        <Label className="form-label">User Type</Label>
                        <br />
                        <span style={{ marginRight: "5px" }}>Customer</span>
                        <Input
                          name="usertype"
                          value="Customer"
                          style={{ marginRight: "10px" }}
                          type="radio"
                          onChange={handleOptionChange}
                        />
                        <span style={{ marginRight: "5px" }}>Admin</span>
                        <Input
                          name="usertype"
                          value="Admin"
                          style={{ marginRight: "10px" }}
                          type="radio"
                          onChange={handleOptionChange}
                        />
                        <span style={{ marginRight: "5px" }}>Agent</span>
                        <Input
                          name="usertype"
                          value="Agent"
                          style={{ marginRight: "10px" }}
                          type="radio"
                          onChange={handleOptionChange}
                        />
                      </div> */}

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <Alert color="info" className="mt-4">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <p className="mb-0 email">Username - admin_user</p>
                            <p className="mb-1 pass">Password - admin123!</p>
                          </div>
                          <div className="flex-shrink-0">
                            <Link
                              to="#"
                              onClick={handleClick}
                              className="btn btn-primary"
                            >
                              Click Me
                            </Link>
                          </div>
                        </div>
                      </Alert>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div> */}

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SystemUserLogin);

SystemUserLogin.propTypes = {
  history: PropTypes.object,
};
