import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";

const EditRatesToggle = (props) => {
  const { isOpen, toggle } = props;
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <ModalHeader toggle={toggle}>Edit Rates</ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="currency">Currency</Label>
                      <Input
                        name="currency"
                        type="text"
                        className="form-control"
                        value="USD"
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="buying">Buying Rate</Label>
                      <Input
                        name="buying"
                        placeholder="Buying Rate"
                        type="text"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="selling">Selling Rate</Label>
                      <Input
                        name="selling"
                        placeholder="Selling Rate"
                        type="text"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="transfer">Transfer Rate</Label>
                      <Input
                        name="transfer"
                        placeholder="Transfer Rate"
                        type="text"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Submit
            </Button>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  );
};

// EcommerceOrdersModal.propTypes = {
//   toggle: PropTypes.func,
//   isOpen: PropTypes.bool,
// }

export default EditRatesToggle;
