import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// Formik Validation
import * as Yup from "yup";

//Toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// actions
import { userResetPassword } from "../../store/actions";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";

// import images
import profile from "../../../images/profile-img.png";
import logoImg from "../../../images/logo.svg";
import lightlogo from "../../../images/logo-light.svg";
import { useDispatch } from "react-redux";
import withRouter from "../../components/Common/withRouter";
import axios from "axios";
import { getApiData, postApiData, putApiData } from "../../helpers/axiosHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCoffee } from "@fortawesome/free-solid-svg-icons";

const ResetSuccess = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //meta title
  document.title = "Reset Password | Crescent Exchange";

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      password_confirmation: "",
      token: props.router.params.token,
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      const response = await putApiData(
        "api/User/UpdateUserResetPassword",
        JSON.stringify(values)
      );
      if (response.success === true) {
        navigate("/reset-success");
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 3000, // Resolve the Promise when the toast is closed
        });
      }
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Success !</h5>
                        <p>Email successfully sent.</p>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{
                                height: "30px",
                                color: "green",
                              }}
                            />
                            {/* <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            /> */}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div
                    className="p-2"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <ToastContainer closeButton={false} limit={1} />
                    <p>
                      Reset link to change password <br /> was successfully sent
                      to your email.
                      <br /> You can leave the page.
                    </p>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Sign In here
                  </Link>{" "}
                </p> */}
                <p>© {new Date().getFullYear()} Crescent Exchange</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetSuccess);
