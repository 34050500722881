import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

const DeleteUserToggle = (props) => {
  const { isOpen, toggle } = props;
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Order Details</ModalHeader>
        <ModalBody>Are you sure to delete the current record?</ModalBody>
        <ModalFooter>
          <Button type="button" color="danger" onClick={toggle}>
            Delete
          </Button>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

// EcommerceOrdersModal.propTypes = {
//   toggle: PropTypes.func,
//   isOpen: PropTypes.bool,
// }

export default DeleteUserToggle;
