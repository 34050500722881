import React from "react"
import { Container, Row } from "reactstrap"
import { Link } from "react-router-dom"; // Import Link component
import Transfers from "../Transfers/TransfersPage"
import Exchange from "../Transfers/ExchangePage"

//Import Components
import CardBox from "./card-box"

const CardsMini = () => {
  const coins = [
    {
      title: "Efficient",
      color: "info",
      icon: "bx bx-purchase-tag-alt",
      value: "Stop queuing. Order online. Collect anytime.", 
      rate: "0.0001.12 ( 0.1 % ) ",
      isIncrease: true,
    },
    {
      title: "Competitive Rates",
      color: "primary",
      icon: "mdi mdi-google-chrome",
      value: "Competitive online currency bookings (from 11 Oct 2024)",
      rate: "004.12 ( 0.1 % )",
      isIncrease: false,
    },
    {
      title: "Secure", // Wrap title with Link
      color: "warning",
      icon: "mdi mdi-lock-check",
      value: "Secure & reliable online transfers (from 11 Oct 2024)",
      rate: "0.0012.23 ( 0.2 % )",
      isIncrease: true,
    },
  ]

  return (
    <React.Fragment>
      <section className="section bg-white p-0">
        <Container>
          <div className="currency-price">
            <Row>
              {/* reder card boxes */}
              <CardBox coins={coins} />
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default CardsMini
