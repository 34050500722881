import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import { Button, Card, CardBody, Label } from "reactstrap";

import TableContainer from "../../../components/Common/TableContainer";

import {
  Branch,
  CurrencyType,
  CurrencyValue,
  Customer,
  RequestDate,
  RequestStatus,
} from "./TransactionTableCol";

const TransactionTable = (props) => {
  //   const [modal1, setModal1] = useState(false);
  //   const [addRateModal, setAddRateModal] = useState(false);

  //   const toggleViewModal = () => setModal1(!modal1);
  //   const addRateModalToggle = () => setAddRateModal(!addRateModal);

  const TransactionData = [
    {
      Customer: "Customer",
      Branch: "Branch",
      CurrencyType: "Indian Rupee (INR)",
      CurrencyValue: "83.09",
      RequestDate: "22/01/2024",
      RequestStatus: "Approved",
    },
    {
      Customer: "Customer",
      Branch: "Branch",
      CurrencyType: "Indian Rupee (INR)",
      CurrencyValue: "83.09",
      RequestDate: "22/01/2024",
      RequestStatus: "Pending",
    },
    {
      Customer: "Customer",
      Branch: "Branch",
      CurrencyType: "Indian Rupee (INR)",
      CurrencyValue: "83.09",
      RequestDate: "22/01/2024",
      RequestStatus: "Pending",
    },
    {
      Customer: "Customer",
      Branch: "Branch",
      CurrencyType: "Indian Rupee (INR)",
      CurrencyValue: "83.09",
      RequestDate: "22/01/2024",
      RequestStatus: "Denied",
    },
  ];
  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "Customer",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Customer {...cellProps} />;
        },
      },
      {
        Header: "Branch",
        accessor: "Branch",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Branch {...cellProps} />;
        },
      },
      {
        Header: "Currency Type",
        accessor: "CurrencyType",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <CurrencyType {...cellProps} />;
        },
      },
      {
        Header: "Currency Value",
        accessor: "CurrencyValue",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <CurrencyValue {...cellProps} />;
        },
      },
      {
        Header: "Date",
        accessor: "RequestDate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <RequestDate {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "RequestStatus",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <RequestStatus {...cellProps} />;
        },
      },
      {
        Header: "Edit Status",
        disableFilters: true,
        accessor: "view",
        Cell: (cellProps) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              //   onClick={toggleViewModal}
            >
              Edit Status
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {/* <div className="mb-4 h4 card-title">Rates</div> */}
          <div className="mb-3"></div>
          {/* <div className="text-sm-end">
            <Button
              type="button"
              color="primary"
              className="btn mb-2 me-2"
              onClick={addRateModalToggle}
            >
              <i className="mdi mdi-plus-circle-outline me-1" />
              Add Currency
            </Button>
          </div> */}
          <TableContainer
            columns={columns}
            data={TransactionData}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={6}
            isPageSelect={false}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

// RateTable.propTypes = {
//   orders: PropTypes.array,
//   onGetOrders: PropTypes.func,
// };

export default TransactionTable;
