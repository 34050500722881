import React, { useMemo, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Table,
} from "reactstrap";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Components
import CardUser from "../CardUserAdmin";

const AccountsFeature = (props) => {
  //meta title
  document.title = "Crescent Exchange | Admin Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Dashboard" />

          {/* Card User */}
          <CardUser />

          <Row>Accounts</Row>

          <Row></Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccountsFeature;
