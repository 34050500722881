import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import LatestTranaction from "./LatestTranaction";
import ReceivedTime from './ReceivedTime';
import Promotions from "./promotions"
import Advertisment from "./advertisment"
import Tasks from "./tasks"
import ChatBox from "./chat-box"
import ViewRates from "./view-rates"

const DashboardSaas = props => {
  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "Orders",
      value: "1,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-archive-in",
      title: "Revenue",
      value: "$ 28,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Average Price",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
    },
  ]

  //meta title
  document.title = "Crescent Exchange | Dashboard"

  const [selectedMulti2, setselectedMulti2] = useState(null);

  const optionGroup1 = [
    {
      label: "Currency",
      options: [
        { label: "USA - USD", value: "USD" },
        { label: "Euro- EUR", value: "EUR" },
        { label: "Japan- JPY", value: "JPY" },
        { label: "UK - GBP", value: "GBP" }
      ]
    }
  ];

  function handleMulti2(selectedMulti2) {
    setselectedMulti2(selectedMulti2);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Dashboard" />

          {/* Card User */}
          <CardUser />

          <Row>
            <Col lg="8">
              <LatestTranaction />
            </Col>
            <Col lg="4">
              <ViewRates />
            </Col>
          </Row>

          <Row>
            {/* total selling product */}
            <ReceivedTime />

            {/* tasks */}
            <Promotions />

            {/* chat box */}
            <Advertisment />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardSaas
