const userdata = [
  {
    Username: "customer@email.com",
    UserType: "customer",
    Branch: "",
  },

  {
    Username: "customer2@email.com",
    UserType: "customer",
    Branch: "",
  },
];

const admindata = [
  {
    Username: "admin@email.com",
    UserType: "admin",
    Branch: "All",
  },
  {
    Username: "admin2@email.com",
    UserType: "admin",
    Branch: "branch1",
  },
];

const agentdata = [
  {
    Username: "agent2@email.com",
    UserType: "agent",
    Branch: "branch1",
  },
  {
    Username: "agent@email.com",
    UserType: "agent",
    Branch: "branch2",
  },
];
export { userdata, admindata, agentdata };
