import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import { map } from "lodash";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Table,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import classnames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getUserProfile } from "../../store/actions";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import axios from "axios";
import axios, { getApiData, postApiData } from "../../helpers/axiosHelper";
import Loader from "../../components/Common/Loader";

const ContactsProfile = (props) => {
  //meta title
  document.title = "Profile | Crescent Exchange";

  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const toggleViewModal = () => setModal1(!modal1);
  const [modal1, setModal1] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [salaryRange, setSalaryRange] = useState([]);
  let type = null,
    options = null;
  const [doctype, setDoctype] = useState("I");
  const idlist = [
    "Choose...",
    "Passport",
    "National Id Card",
    "License",
    "Other",
  ];
  const addresslist = ["Choose...", "Telephone Bill", "Gas Bill", "Other"];
  const [fileData, setFileData] = useState(null);
  const [viewDocument, setViewDocument] = useState("");
  const [viewResponse, setViewResponse] = useState([]);
  const [addressData, setAddressData] = useState(null);
  const [financialData, setFinancialData] = useState(null);
  const [docIsValid, setDocIsValid] = useState(true);
  const [loading, setLoading] = useState(true);

  if (doctype === "I" || doctype === null) {
    type = idlist;
  } else {
    type = addresslist;
  }
  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }

  const nationalityList = [
    { label: "Indian", value: "0" },
    { label: "US", value: "1" },
    { label: "Indonesia", value: "2" },
    { label: "UK", value: "3" },
    { label: "Ukraine", value: "4" },
    { label: "Germany", value: "5" },
    { label: "Japan", value: "6" },
  ];

  const fetchSalaryRange = async () => {
    const response = await axios.get("api/SalaryRange/GetAllSalaryRange");
    setSalaryRange(response.data);
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function handleDocTypeChanged(e) {
    docValidation.handleChange(e);
    setDoctype(e.target.value);
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      street: addressData?.Street || "",
      city: addressData?.City || "",
      state: addressData?.State || "",
      country: addressData?.Country || "",
      postalcode: addressData?.PostalCode || "",
      nationality: addressData?.Nationality || "",
    },
    validationSchema: Yup.object({
      street: Yup.string().required("Please Enter Your Street"),
      city: Yup.string().required("Please Enter Your City"),
      state: Yup.string().required("Please Enter Your State"),
      country: Yup.string().required("Please Enter Your Country"),
      postalcode: Yup.string()
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .required("Please Enter Your Postal Code"),
      nationality: Yup.string().required("Please Select Your Nationality"),
    }),
    onSubmit: async (values) => {
      if (addressData.AddressId > 0) {
        const combinedValues = { ...values, AddressId: addressData.AddressId };
        const hasChanges = Object.keys(values).some(
          (key) => values[key] !== validation.initialValues[key]
        );
        if (hasChanges) {
          const updatedResponse = await axios.put(
            `api/Address/UpdateAddress?AddressID=${addressData.AddressId}`,
            combinedValues
          );
          if (updatedResponse.status == "200") {
            toast.success("Address Updated Successfully", {
              position: "top-right",
              autoClose: 3000,
            });
            toggleTab(activeTab + 1);
          }
        }
        toggleTab(activeTab + 1);
      } else {
        const response = await postApiData(
          "api/Address/CreateAddress",
          JSON.stringify(values)
        );
        if (response.success === true) {
          toggleTab(activeTab + 1);
        } else {
          toast.error("Error Status. Contact Admin", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      }
    },
  });

  const getAddressData = async () => {
    try {
      const response = await getApiData(`api/Address/GetAddressByUId`); //GetAllAddress
      const data = response.data;
      setAddressData(data);
    } catch (error) {
      console.log("Error fetching addressData:", error);
    }
  };

  const docValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      DocumentType: "",
      DocumentName: "",
      Document: "",
    },

    validationSchema: Yup.object({
      DocumentType: Yup.string().required("Please Select the Document Type"),
      DocumentName: Yup.string().required("Please Select the Document Name"),
      Document: Yup.string().required("Please Upload the Document"),
    }),

    onSubmit: async (values, { resetForm }) => {
      if (docIsValid) {
        const combinedValues = { ...values, Document: fileData };
        const response = await postApiData(
          "api/CustomerDoc/InsertCustomerDoc",
          combinedValues
        );
        if (response.success === true) {
          toast.success("Document Added Successfully", {
            position: "top-right",
            autoClose: 3000,
          });
          tog_standard();
          resetForm();
          fetchDocument();
        } else {
          toast.error("Error Status. Contact Admin", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      } else {
        toast.error("File Size Should be less than 5MB", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    },
  });

  function handleFileChange(e) {
    setDocIsValid(true);
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 5000 * 1024) {
      toast.error("File Size Should be less than 5MB", {
        position: "top-right",
        autoClose: 3000,
      });
      setDocIsValid(false);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      let base64String = "";
      const indexOfComma = reader.result.indexOf(",");
      if (indexOfComma !== -1) {
        base64String = reader.result.substring(indexOfComma + 1);
      }
      setFileData(base64String);
    };
    reader.readAsDataURL(file);
  }

  const viewDoc = (index) => {
    const data = viewResponse[index].document;
    setViewDocument(data);
    toggleViewModal();
  };

  const fetchDocument = async () => {
    try {
      const response = await axios.get(
        "api/CustomerDoc/GetCustDocByCustomerId"
      );
      setViewResponse(response.data);
    } catch (error) {
      console.log("Erro in getting Doc:", error);
    }
  };

  function getDocumentType(obj) {
    if (obj === "I") {
      return "Identity Proof";
    } else if (obj === "A") {
      return "Address Proof";
    } else if (obj === "O") {
      return "Others";
    } else {
      return "";
    }
  }

  const financialValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      profession: financialData?.Profession || "",
      AnnualSalaryRangeId: financialData?.AnnualSalaryRangeId || "",
      networth: financialData?.NetWorth || "",
      ExpectedTransAmtMth: financialData?.ExpectedTransAmtMth || "",
    },
    validationSchema: Yup.object({
      networth: Yup.string().matches(/^[0-9]*$/, "Please enter only numbers"),
      ExpectedTransAmtMth: Yup.string().matches(
        /^[0-9]*$/,
        "Please enter only numbers"
      ),
    }),

    onSubmit: async (values) => {
      if (financialData.CustFinId > 0) {
        const hasChanges = Object.keys(values).some(
          (key) => values[key] !== financialValidation.initialValues[key]
        );
        const combinedValues = {
          ...values,
          CustFinId: financialData.CustFinId,
        };
        if (hasChanges) {
          const updatedResponse = await axios.put(
            `api/CustomerFinancial/UpdateCustomerFinancial?CustFinID=${financialData.CustFinId}`,
            combinedValues
          );
          if (updatedResponse.status == "200") {
            toast.success("Financial Details Updated Successfully", {
              position: "top-right",
              autoClose: 3000,
            });
          }
        }
      } else {
        const response = await postApiData(
          "api/CustomerFinancial/CreateCustomerFinancial",
          JSON.stringify(values)
        );
        console.log(response);
        if (response.success === true) {
          toast.success("Financial Details Saved Successfully", {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.error("Error Status. Contact Admin", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      }
    },
  });

  const getFinancialData = async () => {
    try {
      const response = await axios.get("api/CustomerFinancial/GetAccountByUId");
      const data = response.data;
      setFinancialData(data);
    } catch (error) {
      console.log("Error fetching financialData:", error);
    }
  };

  function handleTabChange(e) {
    e.preventDefault();
    if (activeTab === 1) {
      validation.handleSubmit();
    } else if (activeTab === 2) {
      if (docValidation.isValid) {
        toggleTab(activeTab + 1);
      } else {
        toast.error("Reupload the Documents", {
          position: "top-right",
          autoClose: "2000",
        });
      }
    } else if (activeTab === 3) {
      financialValidation.handleSubmit();
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const addressResponse = await axios.get("api/Address/GetAddressByUId");
      const address = await addressResponse.data;

      const documentResponse = await axios.get(
        "api/CustomerDoc/GetCustDocByCustomerId"
      );
      const document = await documentResponse.data;

      const financialResponse = await axios.get(
        "api/CustomerFinancial/GetAccountByUId"
      );
      const financial = await financialResponse.data;
      setLoading(false);

      setAddressData(address);
      setViewResponse(document);
      setFinancialData(financial);

      if (address && document.length > 0 && financial) {
        setactiveTab(1);
      } else if (address && document.length > 0) {
        setactiveTab(3);
      } else if (address && financial) {
        setactiveTab(2);
      } else if (address) {
        setactiveTab(2);
      } else {
        setactiveTab(1);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (activeTab === 3) {
      fetchSalaryRange();
    }
  }, [activeTab]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Profile" />
            <ToastContainer closeButton={false} limit={1} />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Profile</h4>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => {
                                setactiveTab(1);
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span> Address
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => {
                                setactiveTab(2);
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">2.</span> Document List
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                              onClick={() => {
                                setactiveTab(3);
                              }}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">3.</span>Financial
                              Details
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          <TabPane tabId={1}>
                            <Form
                              className="needs-validation"
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit(e);
                                return false;
                              }}
                            >
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="street">Street</Label>
                                    <RequiredAsterisk />
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="street"
                                      placeholder="Enter street"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.street || ""}
                                      invalid={
                                        validation.touched.street &&
                                        validation.errors.street
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.street &&
                                    validation.errors.street ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.street}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="city">City</Label>
                                    <RequiredAsterisk />
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="city"
                                      placeholder="Enter City"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.city || ""}
                                      invalid={
                                        validation.touched.city &&
                                        validation.errors.city
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.city &&
                                    validation.errors.city ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.city}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="state">State</Label>
                                    <RequiredAsterisk />
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="state"
                                      placeholder="Enter State"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.state || ""}
                                      invalid={
                                        validation.touched.state &&
                                        validation.errors.state
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.state &&
                                    validation.errors.state ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.state}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="country">Country</Label>
                                    <RequiredAsterisk />
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="country"
                                      placeholder="Enter Country"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.country || ""}
                                      invalid={
                                        validation.touched.country &&
                                        validation.errors.country
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.country &&
                                    validation.errors.country ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.country}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="postalcode">Postal Code</Label>
                                    <RequiredAsterisk />
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="postalcode"
                                      placeholder="Enter Postal Code"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.postalcode || ""}
                                      invalid={
                                        validation.touched.postalcode &&
                                        validation.errors.postalcode
                                          ? true
                                          : false
                                      }
                                    />
                                    {financialValidation.errors.postalcode ? (
                                      <div
                                        className="text-danger"
                                        style={{ "font-size": "80%" }}
                                      >
                                        {financialValidation.errors.postalcode}
                                      </div>
                                    ) : null}
                                    {validation.touched.postalcode &&
                                    validation.errors.postalcode ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.postalcode}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="nationality">
                                      Nationality
                                    </Label>
                                    <RequiredAsterisk />
                                    <Select
                                      defaultValue="0"
                                      id="nationality"
                                      name="nationality"
                                      value={nationalityList.find(
                                        (option) =>
                                          option.value ===
                                          validation.values.nationality
                                      )}
                                      onBlur={() =>
                                        validation.setFieldTouched(
                                          "nationality",
                                          true
                                        )
                                      }
                                      onChange={(selectedOption) =>
                                        validation.setFieldValue(
                                          "nationality",
                                          selectedOption.value
                                        )
                                      }
                                      options={nationalityList}
                                      className="select2-selection"
                                      invalid={
                                        validation.touched.nationality &&
                                        validation.errors.nationality
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.errors.nationality &&
                                    validation.touched.nationality ? (
                                      <span
                                        className="text-danger"
                                        style={{ "font-size": "80%" }}
                                      >
                                        {validation.errors.nationality}
                                      </span>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                          </TabPane>
                          <TabPane tabId={2}>
                            <div>
                              <Col lg="12">
                                <Card>
                                  <CardBody>
                                    <CardTitle className="h4 mb-4">
                                      Document List
                                    </CardTitle>
                                    <div className="wizard mb-4">
                                      <div className="actions">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            tog_standard();
                                          }}
                                          className="btn btn-primary"
                                          data-toggle="modal"
                                          data-target="#myModal"
                                        >
                                          Add
                                        </button>
                                        <Modal
                                          isOpen={modal_standard}
                                          toggle={() => {
                                            tog_standard();
                                          }}
                                        >
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title mt-0"
                                              id="myModalLabel"
                                            >
                                              Manage Documents
                                            </h5>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setmodal_standard(false);
                                              }}
                                              className="close"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">
                                                &times;
                                              </span>
                                            </button>
                                          </div>
                                          <Row>
                                            <Col lg={12}>
                                              <Card>
                                                <CardBody>
                                                  <Row>
                                                    <Col sm={6}>
                                                      <div className="mt-4">
                                                        <div>
                                                          <label className="col-md-10 col-form-label">
                                                            Document Type
                                                          </label>
                                                          <div>
                                                            <div className="col-md-10">
                                                              <select
                                                                defaultValue="0"
                                                                className="form-select"
                                                                name="DocumentType"
                                                                id="DocumentType"
                                                                onChange={
                                                                  handleDocTypeChanged
                                                                }
                                                                onBlur={
                                                                  docValidation.handleBlur
                                                                }
                                                                value={
                                                                  docValidation
                                                                    .values
                                                                    .DocumentType
                                                                }
                                                                invalid={
                                                                  docValidation
                                                                    .touched
                                                                    .DocumentType &&
                                                                  docValidation
                                                                    .errors
                                                                    .DocumentType
                                                                    ? true
                                                                    : false
                                                                }
                                                              >
                                                                <option defaultValue="0">
                                                                  Choose...
                                                                </option>
                                                                <option value="I">
                                                                  ID Proof
                                                                </option>
                                                                <option value="A">
                                                                  Address Proof
                                                                </option>
                                                              </select>
                                                              {docValidation
                                                                .errors
                                                                .DocumentType &&
                                                              docValidation
                                                                .touched
                                                                .DocumentType ? (
                                                                <span
                                                                  className="text-danger"
                                                                  style={{
                                                                    "font-size":
                                                                      "80%",
                                                                  }}
                                                                >
                                                                  {
                                                                    docValidation
                                                                      .errors
                                                                      .DocumentType
                                                                  }
                                                                </span>
                                                              ) : null}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Col>

                                                    <Col sm={6}>
                                                      <div className="mt-4">
                                                        <div>
                                                          <label className="col-md-10 col-form-label">
                                                            Document Name
                                                          </label>
                                                          <div className="col-md-10">
                                                            <select
                                                              defaultValue="0"
                                                              className="form-select"
                                                              id="DocumentName"
                                                              onChange={
                                                                docValidation.handleChange
                                                              }
                                                              onBlur={
                                                                docValidation.handleBlur
                                                              }
                                                              value={
                                                                docValidation
                                                                  .values
                                                                  .DocumentName
                                                              }
                                                              invalid={
                                                                docValidation
                                                                  .touched
                                                                  .DocumentName &&
                                                                docValidation
                                                                  .errors
                                                                  .DocumentName
                                                                  ? true
                                                                  : false
                                                              }
                                                            >
                                                              {options}
                                                            </select>
                                                            {docValidation
                                                              .errors
                                                              .DocumentName &&
                                                            docValidation
                                                              .touched
                                                              .DocumentName ? (
                                                              <span
                                                                className="text-danger"
                                                                style={{
                                                                  "font-size":
                                                                    "80%",
                                                                }}
                                                              >
                                                                {
                                                                  docValidation
                                                                    .errors
                                                                    .DocumentName
                                                                }
                                                              </span>
                                                            ) : null}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col sm={12}>
                                                      <div className="mt-12">
                                                        <div>
                                                          <Label className="form-label">
                                                            Select Document
                                                          </Label>
                                                          <div className="col-md-10">
                                                            <Input
                                                              type="file"
                                                              className="form-control"
                                                              id="Document"
                                                              aria-describedby="inputGroupFileAddon04"
                                                              aria-label="Upload"
                                                              value={
                                                                docValidation
                                                                  .values
                                                                  .Document
                                                              }
                                                              onChange={(e) => {
                                                                handleFileChange(
                                                                  e
                                                                );
                                                                docValidation.handleChange(
                                                                  e
                                                                );
                                                              }}
                                                              onBlur={
                                                                docValidation.handleBlur
                                                              }
                                                              invalid={
                                                                docValidation
                                                                  .touched
                                                                  .Document &&
                                                                docValidation
                                                                  .errors
                                                                  .Document
                                                                  ? true
                                                                  : false
                                                              }
                                                            />
                                                            {docValidation
                                                              .errors
                                                              .Document &&
                                                            docValidation
                                                              .touched
                                                              .Document ? (
                                                              <span
                                                                className="text-danger"
                                                                style={{
                                                                  "font-size":
                                                                    "80%",
                                                                }}
                                                              >
                                                                {
                                                                  docValidation
                                                                    .errors
                                                                    .Document
                                                                }
                                                              </span>
                                                            ) : null}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </CardBody>
                                              </Card>
                                            </Col>
                                          </Row>

                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                tog_standard();
                                              }}
                                              className="btn btn-secondary "
                                              data-dismiss="modal"
                                            >
                                              Close
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-primary "
                                              onClick={
                                                docValidation.handleSubmit
                                              }
                                            >
                                              Save changes
                                            </button>
                                          </div>
                                        </Modal>
                                      </div>
                                    </div>
                                    <Row>
                                      <div className="table-responsive">
                                        <Table className="table table-striped mb-0">
                                          <thead className="table-light">
                                            <tr>
                                              <th>#</th>
                                              <th>Document Type</th>
                                              <th>Document Name</th>
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {viewResponse
                                              ? viewResponse.map(
                                                  (arr, index) => (
                                                    <tr key={index}>
                                                      <th scope="row">
                                                        {index + 1}
                                                      </th>
                                                      <td>
                                                        {getDocumentType(
                                                          arr.documentType
                                                        )}
                                                      </td>
                                                      <td>
                                                        {arr.documentName}
                                                      </td>
                                                      <td>
                                                        <Button
                                                          type="button"
                                                          color="primary"
                                                          className="btn-sm btn-rounded"
                                                          onClick={() =>
                                                            viewDoc(index)
                                                          }
                                                        >
                                                          View
                                                        </Button>
                                                      </td>
                                                    </tr>
                                                  )
                                                )
                                              : null}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </Row>
                                    <Modal
                                      size="lg"
                                      isOpen={modal1}
                                      toggle={toggleViewModal}
                                    >
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title mt-0"
                                          id="myModalLabel"
                                        >
                                          View Document
                                        </h5>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setModal1(false);
                                          }}
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </div>
                                      <Row>
                                        <Col>
                                          <Card>
                                            <CardBody>
                                              <img
                                                src={`data:image/png;base64,${viewDocument}`}
                                                alt="Base64 Image"
                                                height="100%"
                                                width="100%"
                                              />
                                            </CardBody>
                                          </Card>
                                        </Col>
                                      </Row>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setModal1(false);
                                          }}
                                          className="btn btn-secondary "
                                          data-dismiss="modal"
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </Modal>
                                  </CardBody>
                                </Card>
                              </Col>
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div>
                              <Form>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="profession">Profession</Label>
                                      <Input
                                        type="text"
                                        id="profession"
                                        placeholder="Enter Profession"
                                        className="form-control"
                                        value={
                                          financialValidation.values.profession
                                        }
                                        onChange={
                                          financialValidation.handleChange
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="AnnualSalaryRange">
                                        Annual Salary Range
                                      </Label>
                                      <select
                                        id="AnnualSalaryRangeId"
                                        placeholder="Select Annual Salary Range"
                                        className="form-select"
                                        value={
                                          financialValidation.values
                                            .AnnualSalaryRangeId
                                        }
                                        onChange={
                                          financialValidation.handleChange
                                        }
                                      >
                                        {salaryRange.map((e) => (
                                          <option
                                            key={e.salaryRangeId}
                                            value={e.salaryRangeId}
                                          >
                                            {e.rangeText}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="networth">Net Worth</Label>
                                      <Input
                                        type="text"
                                        id="networth"
                                        placeholder="Enter Net Worth"
                                        className="form-control"
                                        value={
                                          financialValidation.values.networth
                                        }
                                        onChange={
                                          financialValidation.handleChange
                                        }
                                      />
                                      {financialValidation.errors.networth ? (
                                        <div
                                          className="text-danger"
                                          style={{ "font-size": "80%" }}
                                        >
                                          {financialValidation.errors.networth}
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="ExpectedTransAmtMth">
                                        Expected Trans Amt Mth
                                      </Label>
                                      <Input
                                        type="text"
                                        id="ExpectedTransAmtMth"
                                        placeholder="Enter Expected Trans Amt Mth"
                                        className="form-control"
                                        value={
                                          financialValidation.values
                                            .ExpectedTransAmtMth
                                        }
                                        onChange={
                                          financialValidation.handleChange
                                        }
                                      />
                                      {financialValidation.errors
                                        .ExpectedTransAmtMth ? (
                                        <div
                                          className="text-danger"
                                          style={{ "font-size": "80%" }}
                                        >
                                          {
                                            financialValidation.errors
                                              .ExpectedTransAmtMth
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1);
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li className={activeTab === 3 ? "next" : "next"}>
                            <Link to="#" onClick={handleTabChange}>
                              {activeTab < 3 ? "Save & Next" : "Save"}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile));
