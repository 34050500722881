import { useState } from "react";
import { Input } from "reactstrap";

function RateMasterColumn() {
  const [buyingCell, setBuyingCellEmpty] = useState(true);
  const [sellingCell, setSellingCellEmpty] = useState(true);
  const [transferCell, setTransferCellEmpty] = useState(true);

  const [columnData, setColumnData] = useState({
    buying: "",
  });

  const changeRate = (e) => {
    const { name, value } = e.target;

    setColumnData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (name === "buying") {
      if (value === "") {
        setBuyingCellEmpty(false);
      } else {
        alert("not empty");
        setBuyingCellEmpty(true);
      }
    } else if (name === "selling") {
      if (value === "") {
        setSellingCellEmpty(false);
      } else {
        setSellingCellEmpty(true);
      }
    } else if (name === "transfer") {
      if (value === "") {
        setTransferCellEmpty(false);
      } else {
        setTransferCellEmpty(true);
      }
    }
  };
}

const Buying = (cell) => {
  return (
    <Input
      name="buying"
      className="rateCell"
      type="input"
      onChange={RateMasterColumn.changeRate}
      defaultValue={cell.value ? cell.value : ""}
    />
  );
};

const Selling = (cell) => {
  return (
    <Input
      name="selling"
      className="rateCell"
      type="input"
      onChange={RateMasterColumn.changeRate}
      defaultValue={cell.value ? cell.value : ""}
      readOnly
    />
  );
};

const Transfer = (cell) => {
  return (
    <Input
      name="transfer"
      className="rateCell"
      type="input"
      onChange={RateMasterColumn.changeRate}
      defaultValue={cell.value ? cell.value : ""}
      readOnly
    />
  );
};

const Currency = (cell) => {
  return cell.value ? cell.value : "";
};

const CurrencyCode = (cell) => {
  return cell.value ? cell.value : "";
};

const RateId = (cell) => {
  return cell.value ? cell.value : "";
};

const ID = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  Buying,
  Selling,
  Transfer,
  Currency,
  RateMasterColumn,
  CurrencyCode,
  RateId,
  ID,
};
