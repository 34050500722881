import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

//Import Components
import Accordian from "./accordian"

const FAQs = () => {
  const [activeTab, setactiveTab] = useState("1")

  //meta title
  document.title = "Crescent Exchange | Home";

  return (
    <React.Fragment>
      <section className="section bg-white" id="faqs">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h4>Frequently asked questions</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row>
                  <Col lg="10" sm="12">
                    <Card>
                      <CardBody>
                          <Accordian
                            question1="How do I exchange currency with your retail money-changing service ?"
                            answer1="To exchange currency at our retail money-changing service, you can visit our premises with your original identification documents and the currency you wish to exchange. Our staff will assist you with the necessary procedures and provide you with the current exchange rates."
                            question2="Can I pre-book currency for collection at your local premises ?"
                            answer2="Yes, you can pre-book currency for collection at our local premises through our online platform. Simply select the desired currency and amount, and choose the collection date. We will ensure that the requested currency is available for you to collect on the specified date."
                            question3="How long does it take for an online remittance transfer to be completed ?"
                            answer3="The duration for an online remittance transfer can vary depending on various factors such as the destination country, recipient's bank, and any intermediary banks involved. Typically, it takes 1-3 business days for the funds to reach the recipient's account. However, please note that the exact time may vary and can be influenced by factors beyond our control."
                            question4="What are the fees associated with your remittance services ?"
                            answer4="We strive to offer competitive and transparent fees for our remittance services. The fees can vary based on the transfer amount, destination country, and chosen transfer method. Our staff will provide you with detailed information on the fees involved before you proceed with the remittance."
                            question5="Can I track the status of my remittance transfer ?"
                            answer5="Yes, you can track the status of your remittance transfer through our online platform. Once your transfer is initiated, you will receive a unique transaction ID or reference number. You can use this ID to track the progress of your transfer online and stay updated on its status."
                          />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default FAQs
