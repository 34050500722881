import React, { useState } from "react";
import PropTypes from "prop-types";
import currencies from "../../../common/data/allcurrencies";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";

//Toast
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { postApiData, putApiData } from "../../../helpers/axiosHelper";

const AddRatesToggle = (props) => {
  const { isOpen, toggle } = props;

  const [addRates, setAddRates] = useState({
    currency: "",
    buying: "",
    selling: "",
    transfer: "",
  });

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      currency: setAddRates.currency,
      buying: setAddRates.buying,
      selling: setAddRates.selling,
      transfer: setAddRates.selling,
    },

    validationSchema: Yup.object({
      currency: Yup.string().test(
        "required",
        "Please select a currency",
        (value) => value !== "Select Currency"
      ),
      buying: Yup.string().required("Buying rate cannot be empty"),
      selling: Yup.string().required("Selling rate cannot be empty"),
      transfer: Yup.string().required("Transfer rate cannot be empty"),
    }),

    onSubmit: async (values) => {
      const rateValues = [values.buying, values.selling, values.transfer];
      const rateType = [1, 2, 3];

      if (values.currency) {
        const currencyCode = values.currency.match(/\((.*?)\)/)[1];
        const currencyName = values.currency.split(" (")[0];
        let successCount = 0;
        let failureCount = 0;
        try {
          await Promise.all(
            rateType.map(async (rateTypeValue, index) => {
              const requestBody = {
                RateType: rateTypeValue,
                BranchName: "1",
                CurrencyCode: currencyCode,
                CurrencyName: currencyName,
                Value: rateValues[index],
              };
              console.log(requestBody);

              const response = await postApiData(
                `api/RateMaster/InsertRateMaster`,
                JSON.stringify(requestBody)
              );

              if (response.success === true) {
                successCount++;
                if (successCount === rateType.length) {
                  //setVisible(true);
                  toast.success("Success: All rates added successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                      window.location.reload();
                    },
                  });
                  //alert("Success: All rates added successfully");
                  //window.location.reload();
                  //document.getElementById("addRateForm").reset();
                }
              } else {
                failureCount++;
                if (failureCount === rateType.length) {
                  toast.error(response.message, {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                      window.location.reload();
                    },
                  });
                }
              }
            })
          );
        } catch (error) {
          toast.error("Error: " + error.message, {
            position: "top-right",
            autoClose: 3000,
            onClose: () => {
              window.location.reload();
            },
          });
        }
      } else {
        alert("Error: Currency is undefined");
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <Form
          className="add-rate"
          id="addRateForm"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalHeader toggle={toggle}>Add Currency/Rate</ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="currency">Currency</Label>
                      <Input
                        type="select"
                        name="currency"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.currency || ""}
                        invalid={
                          validation.touched.currency &&
                          validation.errors.currency
                            ? true
                            : false
                        }
                      >
                        <option>Select Currency</option>
                        {currencies.map((value) => (
                          <option key={value.key} value={value.name}>
                            {value.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.currency &&
                      validation.errors.currency ? (
                        <FormFeedback type="invalid">
                          {validation.errors.currency}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="buying">Buying Rate</Label>
                      <Input
                        name="buying"
                        placeholder="Buying Rate"
                        type="text"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.buying || ""}
                        invalid={
                          validation.touched.buying && validation.errors.buying
                            ? true
                            : false
                        }
                      />
                      {validation.touched.buying && validation.errors.buying ? (
                        <FormFeedback type="invalid">
                          {validation.errors.buying}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="selling">Selling Rate</Label>
                      <Input
                        name="selling"
                        placeholder="Selling Rate"
                        type="text"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.selling || ""}
                        invalid={
                          validation.touched.selling &&
                          validation.errors.selling
                            ? true
                            : false
                        }
                      />
                      {validation.touched.selling &&
                      validation.errors.selling ? (
                        <FormFeedback type="invalid">
                          {validation.errors.selling}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="transfer">Transfer Rate</Label>
                      <Input
                        name="transfer"
                        placeholder="Transfer Rate"
                        type="text"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.transfer || ""}
                        invalid={
                          validation.touched.transfer &&
                          validation.errors.transfer
                            ? true
                            : false
                        }
                      />
                      {validation.touched.transfer &&
                      validation.errors.transfer ? (
                        <FormFeedback type="invalid">
                          {validation.errors.transfer}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Submit
            </Button>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </div>
      <ToastContainer />
    </Modal>
  );
};

// EcommerceOrdersModal.propTypes = {
//   toggle: PropTypes.func,
//   isOpen: PropTypes.bool,
// }

export default AddRatesToggle;
