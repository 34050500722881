import React, { useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Table,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Components
import TransactionTable from "./TransactionTable";

const TransactionFeature = (props) => {
  //meta title
  document.title = "Crescent Exchange | Admin Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Transaction" breadcrumbItem="Transaction" />

          {/* Card User */}
          {/* <CardUser /> */}

          <Row>
            <TransactionTable />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TransactionFeature;
