import React, { useState } from "react"
import {
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Table,
  Input,
  Label,
} from "reactstrap"
import { Link } from "react-router-dom"
import classnames from "classnames"

//Simple bar
import SimpleBar from "simplebar-react"

import jobs from "../../../images/jobs.png";

const TotalSellongProduct = props => {
  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <Col xl="4">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Advertisments</h4>

            <div className="d-flex">
                            <div>
                                <h4 className="card-title mb-3">Invite your friends to Skote</h4>
                                <p className="text-muted">Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, 
                                but because occasionally.</p>
                                <p className="text-muted">Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, 
                                but because occasionally.</p>
                                <p className="text-muted">Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, 
                                but because occasionally.</p>
                                <div>
                                    <Link to="#" className="btn btn-primary btn-sm"><i className='bx bx-user-plus align-middle'></i> Invite Friends</Link>
                                </div>
                            </div>
                            <div>
                                <img src={jobs} alt="" height="130" />
                            </div>
                        </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default TotalSellongProduct
