import React from "react"
import { Container, Row, Col, Card, CardTitle, CardBody, CardFooter, UncontrolledTooltip, Table } from "reactstrap"

//Import Images
import { Link } from "react-router-dom";
import { icoLandingTeam } from "../../../../common/data";

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "../../../../../../node_modules/swiper/swiper.scss";

const Policy = () => {

  //meta title
  document.title = "Crescent Exchange | Home";

  return (
    <React.Fragment>
      <section className="section bg-white" id="policy">
        <Container fluid={false}>
        <Row>
            <Col lg="12">
              <div className="text-center">
                <h4>Privacy Policy</h4>
              </div>
            </Col>
          </Row>

          <Row>
              <Col>
                <Card>
                  <CardBody>
                    <p className="sub-header">
                      1. We collect and process personal information necessary for the provision of money-changing and remittance services.
                    </p>

                    <div>
                      <p>
                        2. Your personal information will be used solely for the purpose of processing your transactions and complying with legal obligations.
                      </p>
                      <p>
                        3. We implement appropriate security measures to protect your personal information from unauthorized access or disclosure.
                      </p>

                      <p>
                        4. We do not sell, rent, or disclose your personal information to third parties for marketing purposes.
                      </p>

                      <p className="mb-0">
                        5. By using our services, you consent to the collection, use, and storage of your personal information as described in this Privacy Policy.
                      </p>

                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

        </Container>
      </section>
    </React.Fragment>
  )
}

export default Policy
