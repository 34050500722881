import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledCollapse,
  Label,
} from "reactstrap"
import { Link } from "react-router-dom"
import img7 from "../../../../images/product/img-7.png"
import img4 from "../../../../images/product/img-4.png"

import classnames from "classnames";

const EcommerceOrdersModal = props => {

  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);

  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
  };

  const { isOpen, toggle } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Transaction Details</ModalHeader>
        <ModalBody>
          {/* <p className="mb-2">
            Product id: <span className="text-primary">#SK2540</span>
          </p>
          <p className="mb-4">
            Billing Name: <span className="text-primary">Neal Matthews</span>
          </p>

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  <th scope="col">Product</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <div>
                      <img src={img7} alt="" className="avatar-sm"/>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                      <p className="text-muted mb-0">$ 225 x 1</p>
                    </div>
                  </td>
                  <td>$ 255</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <img src={img4} alt="" className="avatar-sm"/>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">Hoodie (Blue)</h5>
                      <p className="text-muted mb-0">$ 145 x 1</p>
                    </div>
                  </td>
                  <td>$ 145</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Sub Total:</h6>
                  </td>
                  <td>
                    $ 400
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Shipping:</h6>
                  </td>
                  <td>
                    Free
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Total:</h6>
                  </td>
                  <td>
                    $ 400
                  </td>
                </tr>
              </tbody>
            </Table>
          </div> */}

          <div className="mt-4">
            {/* <CardTitle className="h4">Flush</CardTitle> */}
            {/* <p className="card-title-desc">
              Add <code>.accordion-flush</code> to remove the default{" "}
              <code>background-color</code>, some borders, and some
              rounded corners to render accordions edge-to-edge with
              their parent container.
            </p> */}

            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      { collapsed: !col9 }
                    )}
                    type="button"
                    onClick={t_col9}
                    style={{ cursor: "pointer" }}
                  >
                    Currency Info
                  </button>
                </h2>

                <Collapse
                  isOpen={col9}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div className="text-muted">
                    <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">From currency :</th>
                            <td>USD</td>
                          </tr>
                          <tr>
                            <th scope="row">To currency :</th>
                            <td>JPY</td>
                          </tr>
                          <tr>
                            <th scope="row">Conversion rate :</th>
                            <td>
                              <Label className="form-check-Label" htmlFor="formrow-customCheck">
                              1 USD = 80 INR
                              </Label>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushTwo">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      { collapsed: !col10 }
                    )}
                    type="button"
                    onClick={t_col10}
                    style={{ cursor: "pointer" }}
                  >
                    Location Info
                  </button>
                </h2>

                <Collapse
                  isOpen={col10}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div className="text-muted">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Location :</th>
                            <td>
                              Singapore 
                              <p className="mt-2">
                                <Link to="#" className="text-primary font-16">Google Map<i className="mdi mdi-chevron-right"></i></Link> 
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Booked Date :</th>
                            <td>30-01-2024</td>
                          </tr>
                          <tr>
                            <th scope="row">Operational Time :</th>
                            <td>02:00 - 02:15 PM</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="headingFlushThree"
                >
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      { collapsed: !col11 }
                    )}
                    type="button"
                    onClick={t_col11}
                    style={{ cursor: "pointer" }}
                  >
                    Review Info
                  </button>
                </h2>
                <Collapse
                  isOpen={col11}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div className="text-muted">
                      <strong className="text-dark">
                        Review text will be displayed here.
                      </strong>{" "}<p className="mt-3" />
                      Review text will be displayed here.Review text will be displayed here.
                      Review text will be displayed here.Review text will be displayed here.
                      Review text will be displayed here.Review text will be displayed here.
                      Review text will be displayed here.Review text will be displayed here.
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>


        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
