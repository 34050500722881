import React, { useState, useEffect } from "react";

import {
  Table,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const FormElements = () => {

  //meta title
  document.title = "Documents | Crescent Exchange"

  let type = null, options = null; 
  //let options = null;

  const [doctype, setDoctype] = useState("I"); 

  const [modal1, setModal1] = useState(false);
  const toggleViewModal = () => setModal1(!modal1);

  const idlist = ["Choose...", "Passport", "National Id Card", "License", "Other"];
  const addresslist = ["Choose...", "Telephone Bill", "Gas Bill", "Other"];
  
  if(doctype === "I" || doctype === null){
    type = idlist;
  }else{
    type = addresslist;
  }
  if (type) { 
    options = type.map((el) => <option key={el}>{el}</option>); 
  } 

  function handleDocTypeChanged(e) {
    setDoctype(e.target.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Manage Documents" />

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Manage documents</CardTitle>
                  <Row>
                    <Col sm={6}>
                      <div className="mt-4">
                        <div>
                          <label className="col-md-10 col-form-label">Document Type</label>
                          <Row>
                            <Col sm={6}>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="I"
                                  onChange={handleDocTypeChanged}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Id Proof
                                </label>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios2"
                                  value="A"
                                  onChange={handleDocTypeChanged}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios2"
                                >
                                  Address Proof
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>

                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="mt-4">
                        <div>
                          <label className="col-md-10 col-form-label">Document Name</label>
                          <div className="col-md-10">
                            <select defaultValue="0" className="form-select" name="docname" id="docname">
                            { 
                              options 
                            } 
                            </select>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div className="mt-4">
                        <div>
                          <Label className="form-label">Select Document</Label>
                          <div className="input-group">
                            <Input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" />
                            <button className="btn btn-primary" type="button" id="inputGroupFileAddon04">Upload</button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Document List</CardTitle>
                  <Row>
                    <Col sm={12}>
                      <div className="table-responsive">
                        <Table className="table table-striped mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>#</th>
                              <th>Document Type</th>
                              <th>Document Name</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>Id Proof</td>
                              <td>Passport</td>
                              <td>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-sm btn-rounded"
                                  onClick={toggleViewModal}
                                >
                                  View
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>Address Proof</td>
                              <td>Telephone Bill</td>
                              <td>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-sm btn-rounded"
                                  onClick={toggleViewModal}
                                >
                                  View
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>Other</td>
                              <td>Gas Bill</td>
                              <td>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-sm btn-rounded"
                                  onClick={toggleViewModal}
                                >
                                  View
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
