import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import CardUser from "./CardUserAdmin";
import LatestTranaction from "./LatestTranaction";
import CardUserAdmin from "./CardUserAdmin";

const AdminDashboard = (props) => {
  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "Orders",
      value: "1,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-archive-in",
      title: "Revenue",
      value: "$ 28,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Average Price",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
    },
  ];

  //meta title
  document.title = "Crescent Exchange | Admin Dashboard";

  const [selectedMulti2, setselectedMulti2] = useState(null);

  const optionGroup1 = [
    {
      label: "Currency",
      options: [
        { label: "USA - USD", value: "USD" },
        { label: "Euro- EUR", value: "EUR" },
        { label: "Japan- JPY", value: "JPY" },
        { label: "UK - GBP", value: "GBP" },
      ],
    },
  ];

  function handleMulti2(selectedMulti2) {
    setselectedMulti2(selectedMulti2);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Dashboard" />

          {/* Card User */}
          <CardUserAdmin />

          <Row>
            <Col>
              <LatestTranaction />
            </Col>
            {/* <Col lg="4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">View Rates</CardTitle>
                  <p class="card-title-desc">
                    Get our best exchange rates* when you order online. The
                    rates shown below are Travelex SELL rates only. For buying
                    rates please see instructions here.
                  </p>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Current country/currency</Label>
                          <Select
                            value={selectedMulti2}
                            onChange={() => {
                              handleMulti2();
                            }}
                            options={optionGroup1}
                            className="select2-selection"
                            isLoading={true}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Destination country/currency</Label>
                          <Select
                            value={selectedMulti2}
                            onChange={() => {
                              handleMulti2();
                            }}
                            options={optionGroup1}
                            className="select2-selection"
                            isLoading={true}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">USD</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-InputCity"
                            placeholder="Enter USD"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">JPY</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-InputCity"
                            placeholder=""
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <div className="form-check">
                        <Label
                          className="form-check-Label"
                          htmlFor="formrow-customCheck"
                        >
                          1 USD = 80 INR
                        </Label>
                      </div>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Submit
                      </button>
                    </div>
                  </Form>
                  <p class="lh-sm mt-4">
                    Exchange rates last updated Friday, 15 December 2023 1:15:08
                    PM AEDT. The online exchange rates provided by this Currency
                    Converter are intended as a guide only and should not be
                    used for transactional purposes. All rates are subject to
                    change from time to time without notice. Exchange rates used
                    in-store may differ from those offered online.
                  </p>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminDashboard;
