import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";

//Toast
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getApiData,
  postApiData,
  putApiData,
} from "../../../helpers/axiosHelper";
import TableContainer from "../../../components/Common/TableContainer";
import Swal from "sweetalert2";

const EditDraftRateSheetToggle = ({
  isOpen,
  toggle,
  sendRateSheet,
  rateMasterData,
  rateSheetID,
  rateSheet,
}) => {
  const [rateSheetHistoryData, setRateSheetHistoryData] = useState([]);

  const handleSubmit = async () => {
    //console.log(rateSheetID);
    Swal.fire({
      //title: "Success!",
      text: "Are you sure to make this draft as Active?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes, Make it as Active",
      confirmButtonColor: "#556ee6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const activeDraftSheet = await putApiData(
            `api/RateMaster/UpdateRateSheetDraftToActive?RateSheetID=${rateSheetID}`
          );
          if (activeDraftSheet.success === true) {
            //alert("Submitted");
            rateSheet();
            toast.success("The current draft has set to Active", {
              position: "top-right",
              autoClose: 1000,
              onClose: () => {
                toggle();
              },
            });
          } else {
            Swal.fire({
              text:
                activeDraftSheet.message +
                "! Do you want to elapse the active sheet?",
              icon: "question",
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: "Yes, Elapse it",
              confirmButtonColor: "#556ee6",
            }).then(async (result) => {
              if (result.isConfirmed) {
                const elapseResponse = await putApiData(
                  `api/RateMaster/ForceUpdateActiveRateSheet`
                );
                if (elapseResponse.success === true) {
                  handleSubmit();
                } else {
                  alert("Error in creating sheet");
                }
              }
            });
          }
        } catch (error) {
          toast.error("Error: " + error.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      }
    });
  };

  const editUpdateDraft = () => {
    console.log(sendRateSheet);
    Swal.fire({
      //title: "Success!",
      text: "Are you sure to update this draft?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes, Update it",
      confirmButtonColor: "#556ee6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const requestBodyRateHistory = sendRateSheet.map((item) => ({
            rateHistoryId: item.rateHistoryId,
            value: item.value,
          }));
          console.log(requestBodyRateHistory);
          const rateHistoryResponse = await putApiData(
            `api/RateHistory/UpdateRateHistory`,
            JSON.stringify(requestBodyRateHistory)
          );
          if (rateHistoryResponse.success === true) {
            //alert("Submitted");
            toast.success("Draft has been Updated", {
              position: "top-right",
              autoClose: 1000,
              onClose: () => {
                toggle();
              },
            });
          } else {
            toast.error(rateHistoryResponse.message, {
              position: "top-right",
              autoClose: 3000,
              // onClose: () => {
              //   window.location.reload();
              // },
            });
          }
        } catch (error) {
          toast.error("Error: " + error.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "index",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "RateSheetID",
        accessor: "rateSheetId",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Branch",
        accessor: "branchId",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Rate Type",
        accessor: "rateType",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Currency Code",
        accessor: "currencyCode",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },

      {
        Header: "Value",
        accessor: "value",
        disableFilters: true,
        filterable: false,
        show: false,
        Cell: (cellProps) => {
          return (
            <Input
              name="value"
              className="cell"
              type="input"
              defaultValue={cellProps.value ? cellProps.value : ""}
              onChange={(e) => {
                const newValue = e.target.value;
                const { row } = cellProps;
                row.original.value = newValue;
              }}
            />
          );
        },
      },
    ],
    []
  );

  // Define a function to handle value change for a specific row
  const handleValueChange = (e, rowIndex) => {
    const newValue = e.target.value;
    // Update the value in rateMasterData state for the specific row
    sendRateSheet((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        value: newValue,
      };
      return updatedData;
    });
    console.log(sendRateSheet);
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      style={{
        minWidth: "1000px",
      }}
    >
      <div
        className="modal-content"
        style={{
          minHeight: "600px",
        }}
      >
        <Form className="add-rate" id="editRateForm">
          <ModalHeader toggle={toggle}>Rate Sheet</ModalHeader>
          <ModalBody>
            <Card>
              <TableContainer
                columns={columns}
                data={sendRateSheet}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={15}
                isPageSelect={false}
                onValueChange={handleValueChange}
              />
            </Card>
          </ModalBody>
          <ModalFooter
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "75%",
              }}
            >
              <Button
                color="success"
                name="createActiveSheet"
                onClick={handleSubmit}
              >
                Create an Active Sheet
              </Button>
            </div>
            <div
              style={{
                width: "20%",
              }}
            >
              <Button
                color="primary"
                style={{
                  marginRight: "5px",
                }}
                name="updateDraft"
                onClick={editUpdateDraft}
              >
                Update Draft
              </Button>
              <Button type="button" color="secondary" onClick={toggle}>
                Close
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </div>
      <ToastContainer />
    </Modal>
  );
};

export default EditDraftRateSheetToggle;
