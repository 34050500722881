import { Badge } from "reactstrap";

const Customer = (cell) => {
  return cell.value ? cell.value : "";
};

const Branch = (cell) => {
  return cell.value ? cell.value : "";
};

const CurrencyType = (cell) => {
  return cell.value ? cell.value : "";
};

const CurrencyValue = (cell) => {
  return cell.value ? cell.value : "";
};

const RequestDate = (cell) => {
  return cell.value ? cell.value : "";
};

const RequestStatus = (cell) => {
  return (
    <Badge
      className={
        "font-size-11 badge-soft-" +
        (cell.value === "Approved"
          ? "success"
          : "warning" && cell.value === "Denied"
          ? "danger"
          : "warning")
      }
    >
      {cell.value}
    </Badge>
  );
};

export {
  Customer,
  Branch,
  CurrencyType,
  CurrencyValue,
  RequestDate,
  RequestStatus,
};
