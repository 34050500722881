import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Table,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Components
import CardUser from "../CardUserAdmin";
import Select from "react-select";
import RateTable from "./RateTable";
import { getApiData } from "../../../helpers/axiosHelper";
import RateSheetTable from "./RateSheetTable";

const ManageRatesFeature = (props) => {
  document.title = "Crescent Exchange | Manage Rate";
  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Manage Rate" breadcrumbItem="Manage Rate" />
          <Nav
            fill
            tabs
            style={{
              marginBottom: "10px",
              width: "50%",
            }}
          >
            <NavItem>
              <NavLink
                className={activeTab === 1 ? "active" : ""}
                style={
                  activeTab === 1
                    ? { backgroundColor: "#556ee6", color: "white" }
                    : {}
                }
                onClick={() => {
                  toggle(1);
                }}
              >
                Rate Sheet
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 2 ? "active" : ""}
                style={
                  activeTab === 2
                    ? { backgroundColor: "#556ee6", color: "white" }
                    : {}
                }
                onClick={() => {
                  toggle(2);
                }}
              >
                Rate Master
              </NavLink>
            </NavItem>
          </Nav>
          <Row>
            <Col lg="12">
              {activeTab === 1 ? <RateSheetTable /> : null}

              {activeTab === 2 ? <RateTable /> : null}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageRatesFeature;
