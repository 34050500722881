import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import { Alert, Button, Card, CardBody, Input, Label } from "reactstrap";

import TableContainer from "../../../components/Common/TableContainer";
import {
  Buying,
  Currency,
  CurrencyCode,
  ID,
  RateId,
  RateMasterColumn,
  Selling,
  Transfer,
} from "./RateTableCol";
import EditRatesToggle from "./EditRates";
import AddRatesToggle from "./AddRates";
import {
  getApiData,
  postApiData,
  putApiData,
} from "../../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RateTable = (props) => {
  const [modal1, setModal1] = useState(false);
  const [addRateModal, setAddRateModal] = useState(false);
  const [rateMasterData, setRateMasterData] = useState([]);
  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);

  const toggleViewModal = () => setModal1(!modal1);
  const addRateModalToggle = () => setAddRateModal(!addRateModal);

  useEffect(() => {
    const rate = async () => {
      try {
        const response = await getApiData(`api/RateMaster/GetAllRateMaster`);
        console.log(response);

        const rateTypeNames = {
          1: "Buying",
          2: "Selling",
          3: "Transfer",
        };
        const tableData = response.map((item) => ({
          currencyName: item.currencyName,
          currencyCode: item.currencyCode,
          rateType: rateTypeNames[item.rateType],
        }));

        // const mappedData = response.reduce((acc, curr) => {
        //   const key = curr.currencyCode;
        //   // If the currency code already exists, update the existing entry
        //   if (acc[key]) {
        //     const existingEntry = acc[key];

        //     // Sort the rateType values while keeping track of corresponding rateId values
        //     const rateTypeArray = existingEntry.rateType
        //       .split(",")
        //       .concat(curr.rateType.toString())
        //       .map(Number);
        //     const rateIdArray = existingEntry.rateId
        //       .split(",")
        //       .concat(curr.rateId.toString())
        //       .map(Number);

        //     const sortedIndexes = rateTypeArray
        //       .map((_, i) => i)
        //       .sort((a, b) => rateTypeArray[a] - rateTypeArray[b]);

        //     const sortedRateTypes = sortedIndexes
        //       .map((index) => rateTypeArray[index])
        //       .join(",");
        //     const sortedRateIds = sortedIndexes
        //       .map((index) => rateIdArray[index])
        //       .join(",");

        //     // Concatenate the current rateId with a comma to the existing entry
        //     acc[key] = {
        //       ...existingEntry,
        //       [rateTypeNames[curr.rateType]]: curr.value,
        //       rateId: sortedRateIds,
        //       rateType: sortedRateTypes,
        //     };
        //   } else {
        //     // Otherwise, create a new entry
        //     acc[key] = {
        //       ...curr,
        //       [rateTypeNames[curr.rateType]]: curr.value,
        //       rateId: curr.rateId.toString(), // Convert the rateId to a string
        //       rateType: curr.rateType.toString(), // Convert the rateType to a string
        //     };
        //   }
        //   return acc;
        // }, {});
        // Convert the object values into an array
        // const tableData = Object.values(mappedData);
        // console.log(tableData);
        setRateMasterData(tableData);
      } catch (error) {
        alert(error);
      }
    };
    rate();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Currency Name",
        accessor: "currencyName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Currency {...cellProps} />;
        },
      },
      {
        Header: "Currency Code",
        accessor: "currencyCode",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CurrencyCode {...cellProps} />;
        },
      },
      {
        Header: "Rate Type",
        accessor: "rateType",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "actions",
        Cell: (cellProps) => {
          return (
            <Button
              type="button"
              color="danger"
              className="btn-sm btn-rounded"
              onClick={() => handleChangeRate(cellProps.row.original)}
            >
              Deactivate
            </Button>
          );
        },
      },
      // },
      // {
      //   Header: "Buying",
      //   accessor: "Buying",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return (
      //       <Input
      //         name="buying"
      //         className="rateCell"
      //         type="input"
      //         defaultValue={cellProps.value ? cellProps.value : ""}
      //         onChange={(e) => {
      //           const newValue = e.target.value;
      //           const { row } = cellProps;
      //           row.original.Buying = newValue;
      //         }}
      //       />
      //     );
      //   },
      // },
      // {
      //   Header: "Selling",
      //   accessor: "Selling",
      //   disableFilters: true,
      //   filterable: false,
      //   show: false,
      //   Cell: (cellProps) => {
      //     return (
      //       <Input
      //         name="selling"
      //         className="rateCell"
      //         type="input"
      //         defaultValue={cellProps.value ? cellProps.value : ""}
      //         onChange={(e) => {
      //           const newValue = e.target.value;
      //           const { row } = cellProps;
      //           row.original.Selling = newValue;
      //         }}
      //       />
      //     );
      //   },
      // },
      // {
      //   Header: "Transfer",
      //   accessor: "Transfer",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: (cellProps) => {
      //     return (
      //       <Input
      //         name="transfer"
      //         className="rateCell"
      //         type="input"
      //         defaultValue={cellProps.value ? cellProps.value : ""}
      //         onChange={(e) => {
      //           const newValue = e.target.value;
      //           const { row } = cellProps;
      //           // Update the Transfer property in the row's original data
      //           row.original.Transfer = newValue;
      //         }}
      //       />
      //     );
      //   },
      // },
      // {
      //   Header: "Actions",
      //   disableFilters: true,
      //   accessor: "actions",
      //   Cell: (cellProps) => {
      //     return (
      //       <Button
      //         type="button"
      //         color="warning"
      //         className="btn-sm btn-rounded"
      //         onClick={() => handleChangeRate(cellProps.row.original)}
      //       >
      //         Update Rates
      //       </Button>
      //     );
      //   },
      // },
    ],
    []
  );

  const handleChangeRate = async (cellProps) => {
    const { rateId, rateType, Buying, Selling, Transfer } = cellProps;
    const rateIds = rateId.split(",");
    const rateTypes = rateType.split(",");
    const values = [Buying, Selling, Transfer];
    let successCount = 0;

    console.log(cellProps);

    try {
      await Promise.all(
        rateIds.map(async (id, index) => {
          const requestBody = {
            RateType: parseInt(rateTypes[index]),
            // BranchName: "1",
            // CurrencyCode: cellProps.currencyCode,
            // CurrencyName: cellProps.currencyName,
            Value: values[index],
          };
          console.log(requestBody);

          const response = await putApiData(
            `api/RateMaster/UpdateRateMaster?RateMasterID=${id}&RateType=${requestBody.RateType}&Value=${requestBody.Value}`
            // JSON.stringify(requestBody)
          );

          if (response.success === true) {
            successCount++;
            if (successCount === rateIds.length) {
              //setVisible(true);
              //alert("Success: All rates updated successfully");

              toast.success("All rates updated successfully", {
                position: "top-right",
                autoClose: 3000,
                onClose: () => {
                  window.location.reload();
                },
              });
            }
          } else {
            toast.error("Rates cannot be updated" + response.message, {
              position: "top-right",
              autoClose: 3000,
              onClose: () => {
                window.location.reload();
              },
            });
          }
        })
      );
    } catch (error) {
      toast.error("Error: " + error.message, {
        position: "top-right",
        autoClose: 3000,
        onClose: () => {
          window.location.reload();
        },
      });
    }
  };

  return (
    <React.Fragment>
      <EditRatesToggle isOpen={modal1} toggle={toggleViewModal} />
      <AddRatesToggle isOpen={addRateModal} toggle={addRateModalToggle} />
      <Alert color="success" isOpen={visible} toggle={onDismiss}>
        Rates Updated Sucessfully
      </Alert>
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Rates</div>
          <div className="mb-3">
            <div className="form-check">
              <Label className="form-check-Label" htmlFor="formrow-customCheck">
                All currency rates are converted from 1 USD (i.e 1 USD = 83.09
                INR)
              </Label>
            </div>
          </div>
          <div className="text-sm-end">
            <Button
              type="button"
              color="primary"
              className="btn mb-2 me-2"
              onClick={addRateModalToggle}
            >
              <i className="mdi mdi-plus-circle-outline me-1" />
              Add Currency
            </Button>
          </div>
          {/* <Alert color="info" isOpen={visible} toggle={onDismiss}>
            I am an alert and I can be dismissed!
          </Alert> */}
          <TableContainer
            columns={columns}
            data={rateMasterData}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={15}
            isPageSelect={false}
          />
        </CardBody>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
};

RateTable.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default RateTable;
