import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import currencies from "../../../common/data/allcurrencies";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";

//Toast
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getApiData,
  postApiData,
  putApiData,
} from "../../../helpers/axiosHelper";
import TableContainer from "../../../components/Common/TableContainer";
import swal from "sweetalert";
import Swal from "sweetalert2";

const AddRateSheetToggle = (props) => {
  const { isOpen, toggle, rateSheet } = props;
  const [rateMasterData, setRateMasterData] = useState([]);
  const [addBranch, setBranch] = useState("");
  const formRef = useRef(null);

  const [tableData, setTableData] = useState({
    currencyName: "",
    currencyCode: "",
    rateType: "",
    value: "",
  });

  const handleChangeBranch = (event) => {
    const { name, value } = event.target;
    setBranch(value);
  };

  const saveAsDraft = async () => {
    Swal.fire({
      text: "Are you sure to make this as draft?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes, Draft it",
      confirmButtonColor: "#556ee6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (addBranch) {
          const requestRateSheetBody = {
            BranchID: 1,
            EndTime: null,
            Status: 2,
          };
          const rateTypeMapping = {
            Buying: 1,
            Selling: 2,
            Transfer: 3,
          };

          const response = await postApiData(
            `api/RateMaster/InsertRateSheet`,
            JSON.stringify(requestRateSheetBody)
          );
          if (response.success === true) {
            try {
              const requestBody = rateMasterData.map((item, rowIndex) => ({
                rateSheetID: response.data.rateSheetId,
                branchId: 1,
                rateType: rateTypeMapping[item.rateType],
                currencyCode: item.currencyCode,
                value: item.value,
              }));
              console.log(requestBody);
              const rateHistoryResponse = await postApiData(
                `api/RateHistory/InsertRateHistory`,
                JSON.stringify(requestBody)
              );
              if (rateHistoryResponse.success === true) {
                //handleValueChange(null, rowIndex, true);
                document.getElementById("addRateForm").reset();
                setBranch("");
                rateSheet();

                toast.success("Sheet has been drafted", {
                  position: "top-right",
                  autoClose: 1000,
                  onClose: () => {
                    toggle();
                  },
                });
              } else {
                toast.error(response.message, {
                  position: "top-right",
                  autoClose: 3000,
                });
              }
            } catch (error) {
              toast.error("Error: " + error.message, {
                position: "top-right",
                autoClose: 3000,
              });
            }
          } else {
            toast.error("Error: " + response.message, {
              position: "top-right",
              autoClose: 3000,
            });
          }
        } else {
          Swal.fire({
            title: "Warning",
            text: "Please select a Branch to proceed",
            icon: "warning",
            confirmButtonColor: "#556ee6",
          });
        }
      }
    });
    // console.log(rateMasterData);
    //console.log(addBranch);
  };

  const rateMasterForSheet = async () => {
    try {
      const response = await getApiData(`api/RateMaster/GetAllRateMaster`);
      //console.log(response);

      const rateTypeNames = {
        1: "Buying",
        2: "Selling",
        3: "Transfer",
      };
      const mappedResponse = response.map((item, index) => ({
        currencyName: item.currencyName,
        currencyCode: item.currencyCode,
        rateType: rateTypeNames[item.rateType],
        value: "",
      }));
      setRateMasterData(mappedResponse);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    rateMasterForSheet();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Currency Name",
        accessor: "currencyName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Currency Code",
        accessor: "currencyCode",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },

      {
        Header: "Rate Type",
        accessor: "rateType",
        disableFilters: true,
        filterable: false,
        show: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Value",
        accessor: "value",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <Input
              name="value"
              className="cell"
              type="input"
              defaultValue={cellProps.value ? cellProps.value : ""}
              onChange={(e) => {
                const newValue = e.target.value;
                const { row } = cellProps;
                row.original.value = newValue;
              }}
            />
          );
        },
      },
    ],
    []
  );

  const handleValueChange = (e, rowIndex, clearValue = false) => {
    const newValue = clearValue ? "" : e.target.value;
    // Update the value in rateMasterData state for the specific row
    setRateMasterData((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        value: newValue,
      };
      return updatedData;
    });
  };

  const handleSubmit = async (values) => {
    if (values.branch) {
      const requestRateSheetBody = {
        BranchID: 1,
        EndTime: null,
        Status: 1,
      };
      const rateTypeMapping = {
        Buying: 1,
        Selling: 2,
        Transfer: 3,
      };
      const response = await postApiData(
        `api/RateMaster/InsertRateSheet`,
        JSON.stringify(requestRateSheetBody)
      );
      if (response.success === true) {
        try {
          const requestBodyRateHistory = values.tableData.map((item) => ({
            rateSheetID: response.data.rateSheetId,
            branchId: 1,
            rateType: rateTypeMapping[item.rateType],
            currencyCode: item.currencyCode,
            value: item.value,
          }));
          console.log(requestBodyRateHistory);
          const rateHistoryResponse = await postApiData(
            `api/RateHistory/InsertRateHistory`,
            JSON.stringify(requestBodyRateHistory)
          );
          if (rateHistoryResponse.success === true) {
            values.branch = "";
            rateSheet();
            toast.success("Success: Added in Rate History Table", {
              position: "top-right",
              autoClose: 1000,
              onClose: () => {
                toggle();
              },
            });
          } else {
            toast.error("Failure:" + response.message, {
              position: "top-right",
              autoClose: 3000,
            });
          }
        } catch (error) {
          toast.error("Error: " + error.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      } else {
        Swal.fire({
          text: response.message + "! Do you want to elapse the active sheet?",
          icon: "question",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes, Elapse it",
          confirmButtonColor: "#556ee6",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const elapseResponse = await putApiData(
              `api/RateMaster/ForceUpdateActiveRateSheet?RateSheetID=${response.data.rateSheetId}`
            );

            if (elapseResponse.success === true) {
              handleSubmit(values);
            } else {
              alert("Error in creating sheet");
            }
          }
        });
      }
    } else {
      Swal.fire({
        title: "Warning",
        text: "Please select a Branch to proceed",
        icon: "warning",
        confirmButtonColor: "#556ee6",
      });
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      branch: addBranch.branch,
      tableData: rateMasterData,
    },

    validationSchema: Yup.object({
      branch: Yup.string().test(
        "required",
        "Please select a Branch",
        (value) => value !== "Select Branch"
      ),
    }),

    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      style={{
        minWidth: "1000px",
      }}
    >
      <div
        className="modal-content"
        style={{
          minHeight: "600px",
        }}
      >
        <Form
          className="add-rate"
          id="addRateForm"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          ref={formRef}
        >
          <ModalHeader toggle={toggle}>Add New Rate Sheet</ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <Label htmlFor="currency">Branch</Label>
                      <Input
                        type="select"
                        name="branch"
                        className="form-select"
                        onChange={(event) => {
                          validation.handleChange(event);
                          handleChangeBranch(event); //
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.branch || ""}
                        invalid={
                          validation.touched.branch && validation.errors.branch
                            ? true
                            : false
                        }
                      >
                        <option>Select Branch</option>
                        <option>All</option>
                        <option>1 - Singapore</option>
                      </Input>
                      {validation.touched.branch && validation.errors.branch ? (
                        <FormFeedback type="invalid">
                          {validation.errors.branch}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <TableContainer
                columns={columns}
                data={rateMasterData}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={15}
                isPageSelect={false}
                onValueChange={handleValueChange}
              />
            </Card>
          </ModalBody>
          <ModalFooter
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "75%",
              }}
            >
              <Button color="success" name="createActiveSheet" type="submit">
                Create an Active Sheet
              </Button>
            </div>
            <div
              style={{
                width: "20%",
              }}
            >
              <Button
                color="primary"
                style={{
                  marginRight: "5px",
                }}
                name="saveAsDraft"
                onClick={saveAsDraft}
              >
                Save as Draft
              </Button>
              <Button type="button" color="secondary" onClick={toggle}>
                Close
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </div>
      <ToastContainer />
    </Modal>
  );
};

// EcommerceOrdersModal.propTypes = {
//   toggle: PropTypes.func,
//   isOpen: PropTypes.bool,
// }

export default AddRateSheetToggle;
