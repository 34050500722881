import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";

const UpdateUserToggle = (props) => {
  const { isOpen, toggle } = props;
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Order Details</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="firstname">First name</Label>
                      <Input
                        name="firstname"
                        placeholder="First name"
                        type="text"
                        className="form-control"
                        id="firstname"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="lastname">Last name</Label>
                      <Input
                        name="lastname"
                        placeholder="Last name"
                        type="text"
                        className="form-control"
                        id="lastname"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="email"
                        className="form-control"
                        id="email"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="mobile">Mobile</Label>
                      <Input
                        name="mobile"
                        placeholder="Mobile"
                        type="text"
                        className="form-control"
                        id="mobile"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="usertype">Usertype</Label>
                      <Input
                        name="usertype"
                        placeholder="Usertype"
                        type="usertype"
                        className="form-control"
                        id="usertype"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="role">Role</Label>
                      <Input
                        name="role"
                        placeholder="Role"
                        type="text"
                        className="form-control"
                        id="role"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="branch">Branch</Label>
                      <Input
                        name="branch"
                        placeholder="Branch"
                        type="input"
                        className="form-control"
                        id="branch"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

// EcommerceOrdersModal.propTypes = {
//   toggle: PropTypes.func,
//   isOpen: PropTypes.bool,
// }

export default UpdateUserToggle;
