// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";

import Landing from "../pages/Authentication/Landing/index";
import Home from "../pages/Authentication/Home";
import Contact from "../pages/Contact/contacts-profile";
import FAQs from "../pages/faqs";

import Dashboard from "../pages/Dashboard/index";
import AgentDashboard from "../pages/Dashboard_Agent/index";
import AdminDashboard from "../pages/Dashboard_Admin/index";
import SystemUserLogin from "../pages/Authentication/SystemUserLogin";
import AgentLogin from "../pages/Authentication/AgentLogin";
import ContactsProfile from "../pages/Contact/contacts-profile";
import ManageDocs from "../pages/ManageDocument/manage-docs";
import ManageUser from "../pages/Dashboard_Admin/ManageUser/ManageUser";
import AccountsFeature from "../pages/Dashboard_Admin/Transaction/accounts";
import ManageRatesFeature from "../pages/Dashboard_Admin/ManageRate/ManageRates";
import TransactionFeature from "../pages/Dashboard_Admin/Transaction/Transaction";
import EmailVerification from "../pages/Authentication/EmailVerification";
import ResetSuccess from "../pages/Authentication/ResetSuccess";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/agentdashboard", component: <AgentDashboard /> },
  { path: "/admindashboard", component: <AdminDashboard /> },
  { path: "/manageuser", component: <ManageUser /> },
  { path: "/accounts", component: <AccountsFeature /> },
  { path: "/managerates", component: <ManageRatesFeature /> },
  { path: "/transaction", component: <TransactionFeature /> },
  { path: "/home", component: <Home /> },
  { path: "/contact", component: <Contact /> },
  { path: "/faqs", component: <FAQs /> },
  { path: "/profile", component: <ContactsProfile /> },
  { path: "/docs", component: <ManageDocs /> },
];

const publicRoutes = [
  {
    path: "/",
    exact: true,
    component: <Landing />,
  },
  { path: "/login", component: <Login /> },
  { path: "/agentlogin", component: <AgentLogin /> },
  { path: "/systemuserlogin", component: <SystemUserLogin /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  {
    path: "/emailverification/:uid",
    component: <EmailVerification />,
  },
  { path: "/reset-password/:token", component: <ResetPassword /> },
  { path: "/reset-success", component: <ResetSuccess /> },
  { path: "/home", component: <Landing /> },
];

export { publicRoutes, authProtectedRoutes };
