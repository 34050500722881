import React, { useMemo, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Table,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Components

import TableContainer from "../../../components/Common/TableContainer";
import { userdata, admindata, agentdata } from "./UserData";
import ViewDetailsToggle from "./ViewDetailsToggle";
import UpdateUserToggle from "./UpdateUserToggle";
import DeleteUserToggle from "./DeleteUserToggle";

const ManageUser = (props) => {
  //meta title
  document.title = "Crescent Exchange | Admin Dashboard";

  const [selectedMulti2, setselectedMulti2] = useState(null);

  function handleMulti2(selectedMulti2) {
    setselectedMulti2(selectedMulti2);
  }

  const Username = (cell) => {
    return cell.value ? cell.value : "";
  };

  const UserType = (cell) => {
    return cell.value ? cell.value : "";
  };

  const Branch = (cell) => {
    return cell.value ? cell.value : "";
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Username",
        accessor: "Username",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Username {...cellProps} />;
        },
      },
      {
        Header: "UserType",
        accessor: "UserType",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <UserType {...cellProps} />;
        },
      },
      {
        Header: "Branch",
        accessor: "Branch",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <Branch {...cellProps} />;
        },
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "actions",
        Cell: (cellProps) => {
          return (
            <>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={toggleViewDetails}
                style={{
                  marginRight: "5px",
                }}
              >
                View Details
              </Button>
              <Button
                type="button"
                color="success"
                className="btn-sm btn-rounded"
                onClick={toggleUpdateDetails}
                style={{
                  marginRight: "5px",
                }}
              >
                Update Details
              </Button>
              <Button
                type="button"
                color="danger"
                className="btn-sm btn-rounded"
                onClick={toggleDeleteUser}
                style={{
                  marginRight: "5px",
                }}
              >
                Delete User
              </Button>
            </>
          );
        },
      },
    ],
    []
  );

  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [viewModal, setViewModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleViewDetails = () => setViewModal(!viewModal);
  const toggleUpdateDetails = () => setUpdateModal(!updateModal);
  const toggleDeleteUser = () => setDeleteModal(!deleteModal);

  return (
    <React.Fragment>
      <ViewDetailsToggle isOpen={viewModal} toggle={toggleViewDetails} />
      <UpdateUserToggle isOpen={updateModal} toggle={toggleUpdateDetails} />
      <DeleteUserToggle isOpen={deleteModal} toggle={toggleDeleteUser} />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Manage User" breadcrumbItem="Manage User" />

          {/* Card User */}
          {/* <CardUser /> */}

          {/* User Tabs */}
          <Nav
            fill
            tabs
            style={{
              marginBottom: "10px",
            }}
          >
            <NavItem>
              <NavLink
                className={activeTab === 1 ? "active" : ""}
                style={
                  activeTab === 1
                    ? { backgroundColor: "#556ee6", color: "white" }
                    : {}
                }
                onClick={() => {
                  toggle(1);
                }}
              >
                Customers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 2 ? "active" : ""}
                style={
                  activeTab === 2
                    ? { backgroundColor: "#556ee6", color: "white" }
                    : {}
                }
                onClick={() => {
                  toggle(2);
                }}
              >
                Agents
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 3 ? "active" : ""}
                style={
                  activeTab === 3
                    ? { backgroundColor: "#556ee6", color: "white" }
                    : {}
                }
                onClick={() => {
                  toggle(3);
                }}
              >
                System users
              </NavLink>
            </NavItem>
          </Nav>
          <Row>
            <Col lg="12">
              {activeTab === 1 ? (
                <TableContainer
                  columns={columns}
                  data={userdata}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  customPageSize={6}
                  isPageSelect={false}
                />
              ) : null}

              {activeTab === 2 ? (
                <TableContainer
                  columns={columns}
                  data={agentdata}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  customPageSize={6}
                  isPageSelect={false}
                />
              ) : null}

              {activeTab === 3 ? (
                <TableContainer
                  columns={columns}
                  data={admindata}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  customPageSize={6}
                  isPageSelect={false}
                />
              ) : null}
            </Col>
            {/* <Col lg="6">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="firstname">First name</Label>
                          <Input
                            name="firstname"
                            placeholder="First name"
                            type="text"
                            className="form-control"
                            id="firstname"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="lastname">Last name</Label>
                          <Input
                            name="lastname"
                            placeholder="Last name"
                            type="text"
                            className="form-control"
                            id="lastname"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="email">Email</Label>
                          <Input
                            name="email"
                            placeholder="Email"
                            type="email"
                            className="form-control"
                            id="email"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="mobile">Mobile</Label>
                          <Input
                            name="mobile"
                            placeholder="Mobile"
                            type="text"
                            className="form-control"
                            id="mobile"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="usertype">Usertype</Label>
                          <Input
                            name="usertype"
                            placeholder="Usertype"
                            type="usertype"
                            className="form-control"
                            id="usertype"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="role">Role</Label>
                          <Input
                            name="role"
                            placeholder="Role"
                            type="text"
                            className="form-control"
                            id="role"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="branch">Branch</Label>
                          <Input
                            name="branch"
                            placeholder="Branch"
                            type="input"
                            className="form-control"
                            id="branch"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col> */}
          </Row>

          <Row></Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageUser;
