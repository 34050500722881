import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './js/setup'
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./js/i18n";

import store from "./js/store";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
            <React.Fragment>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </React.Fragment>
        </Provider>
);


