import React, { useState } from "react"
import {
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Table,
  Input,
  Label,
  Container,
  Row,
  CardTitle,
  Button,
  Form,
  InputGroup,
  TabContent,
  TabPane,
  UncontrolledCollapse,
  CardText,
} from "reactstrap"
import { Link } from "react-router-dom"
import classnames from "classnames"

import Select from "react-select";

//Simple bar
import SimpleBar from "simplebar-react"

import jobs from "../../../images/jobs.png";

const ViewRates = props => {
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const [selectedMulti2, setselectedMulti2] = useState(null);

  const optionGroup1 = [
    {
      label: "Currency",
      options: [
        { label: "USA - USD", value: "USD" },
        { label: "Euro- EUR", value: "EUR" },
        { label: "Japan- JPY", value: "JPY" },
        { label: "UK - GBP", value: "GBP" }
      ]
    }
  ];

  function handleMulti2(selectedMulti2) {
    setselectedMulti2(selectedMulti2);
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4">View Rates</CardTitle>
          <p class="card-title-desc">
            Get our best exchange rates* when you order online. The rates shown below are Travelex SELL rates only. For buying rates please see instructions here.
          </p>

          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Buying</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">Selling</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "3",
                })}
                onClick={() => {
                  toggleCustom("3");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-envelope"></i>
                </span>
                <span className="d-none d-sm-block">Transfer</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            activeTab={customActiveTab}
            className="p-3 text-muted"
          >
            <TabPane tabId="1">
              <Form>
                <p className="mt-4">Buying Details</p>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                    <Label>From currency</Label>
                    <Select
                        value={selectedMulti2}
                        onChange={() => {
                          handleMulti2();
                        }}
                        options={optionGroup1}
                        className="select2-selection"
                        isLoading={true}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                    <Label>To currency</Label>
                    <Select
                        value={selectedMulti2}
                        onChange={() => {
                          handleMulti2();
                        }}
                        options={optionGroup1}
                        className="select2-selection"
                        isLoading={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputCity">USD</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Enter USD Amount"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">JPY</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Enter JPY Amount"
                      />
                    </div>
                  </Col>

                </Row>

                <div className="mb-3">
                  <div className="form-check">
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      1 USD = 80 INR
                    </Label>
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn btn-primary w-md">
                    Submit
                  </button>
                </div>
              </Form>

            </TabPane>
            <TabPane tabId="2">
              <Form>
                <p className="mt-4">Selling Details</p>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                    <Label>From currency</Label>
                    <Select
                        value={selectedMulti2}
                        onChange={() => {
                          handleMulti2();
                        }}
                        options={optionGroup1}
                        className="select2-selection"
                        isLoading={true}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                    <Label>To currency</Label>
                    <Select
                        value={selectedMulti2}
                        onChange={() => {
                          handleMulti2();
                        }}
                        options={optionGroup1}
                        className="select2-selection"
                        isLoading={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputCity">USD</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Enter USD Amount"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">JPY</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Enter JPY Amount"
                      />
                    </div>
                  </Col>

                </Row>

                <div className="mb-3">
                  <div className="form-check">
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      1 USD = 80 INR
                    </Label>
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn btn-primary w-md">
                    Submit
                  </button>
                </div>
              </Form>
            </TabPane>
            <TabPane tabId="3">
            <Form>
                <p className="mt-4">Transfer Details</p>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                    <Label>From currency</Label>
                    <Select
                        value={selectedMulti2}
                        onChange={() => {
                          handleMulti2();
                        }}
                        options={optionGroup1}
                        className="select2-selection"
                        isLoading={true}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                    <Label>To currency</Label>
                    <Select
                        value={selectedMulti2}
                        onChange={() => {
                          handleMulti2();
                        }}
                        options={optionGroup1}
                        className="select2-selection"
                        isLoading={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputCity">USD</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Enter USD Amount"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">JPY</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Enter JPY Amount"
                      />
                    </div>
                  </Col>

                </Row>

                <div className="mb-3">
                  <div className="form-check">
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      1 USD = 80 INR
                    </Label>
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn btn-primary w-md">
                    Submit
                  </button>
                </div>
              </Form>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ViewRates
