import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";

//Toast
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getApiData,
  postApiData,
  putApiData,
} from "../../../helpers/axiosHelper";
import TableContainer from "../../../components/Common/TableContainer";

const ViewRateSheetToggle = ({ isOpen, toggle, sendRateSheet }) => {
  const [rateSheetHistoryData, setRateSheetHistoryData] = useState([]);

  //   const viewRateSheet = async () => {
  //     try {
  //       const response = await getApiData(
  //         `api/RateHistory/GetRateHistorySheet?RateSheetId=${sendRateSheet}`
  //       );
  //       console.log(response);

  //       const rateTypeNames = {
  //         1: "Buying",
  //         2: "Selling",
  //         3: "Transfer",
  //       };
  //       const rateHistories = response.map((item) => ({
  //         rateHistoryId: item.rateHistoryId,
  //         rateSheetId: item.rateSheetId,
  //         branchId: item.branchId,
  //         rateType: rateTypeNames[item.rateType],
  //         currencyCode: item.currencyCode,
  //         value: item.value,
  //       }));
  //       setRateSheetHistoryData(rateHistories);
  //       //console.log(rateHistories);
  //     } catch (error) {
  //       alert(error);
  //     }
  //   };

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "index",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "RateSheetID",
        accessor: "rateSheetId",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Branch",
        accessor: "branchId",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Rate Type",
        accessor: "rateType",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Currency Code",
        accessor: "currencyCode",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },

      {
        Header: "Value",
        accessor: "value",
        disableFilters: true,
        filterable: false,
        show: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
    ],
    []
  );

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      style={{
        minWidth: "1000px",
      }}
    >
      <div
        className="modal-content"
        style={{
          minHeight: "600px",
        }}
      >
        <Form className="add-rate" id="viewRateForm">
          <ModalHeader toggle={toggle}>Rate Sheet</ModalHeader>
          <ModalBody>
            <Card>
              {/* <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <Label htmlFor="currency">Branch</Label>
                      <Input
                        type="select"
                        name="branch"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.branch || ""}
                        invalid={
                          validation.touched.branch && validation.errors.branch
                            ? true
                            : false
                        }
                      >
                        <option>Select Branch</option>
                        <option>All</option>
                        <option>1 - Singapore</option>
                      </Input>
                      {validation.touched.branch && validation.errors.branch ? (
                        <FormFeedback type="invalid">
                          {validation.errors.branch}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody> */}
              <TableContainer
                columns={columns}
                data={sendRateSheet}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={15}
                isPageSelect={false}
              />
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </div>
      <ToastContainer />
    </Modal>
  );
};

export default ViewRateSheetToggle;
