import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// actions
import { loginUser } from "../../store/actions";

// import images
import profile from "../../../images/profile-img.png";
import logo from "../../../images/logo.svg";
import { createSelector } from "reselect";

//Import Components
import Navbar from "./Landing/Navbar/Navbar"
import Section from "./Landing/HeroSection/Section"
import CardsMini from "./Landing/HeroSection/cards-mini"
import AboutUs from "./Landing/AboutUs/about-us"
import Features from "./Landing/Features/features"
import FAQs from "./Landing/Faqs/FAQs"
import Contact from "./Landing/Contact/Contact"
import Terms from "./Landing/Terms/terms"
import Policy from "./Landing/Policy/policy"
import Testimonial from "./Landing/Testimonial/Testimonial"
import Footer from "./Landing/Footer/footer"

const Home = props => {

  //meta title
  document.title="Crescent Exchange | Home";

  const [imglight, setimglight] = useState(true);
  const [navClass, setnavClass] = useState("");

  // Use ComponentDidMount
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true)
  },[])

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 80) {
      setimglight(false)
      setnavClass("nav-sticky")
    } else {
      setimglight(true)
      setnavClass("")
    }
  }

  return (
    <React.Fragment>
      {/* import navbar */}
      <Navbar navClass={navClass} imglight={imglight} />

      {/* Hero section */}
      <Section />

      {/* mini cards */}
      <CardsMini />

      {/* aboutus */}
      <AboutUs />

      {/* features */}
      <Features />

      {/* faqs */}
      <FAQs />

      {/* contact */}
      <Contact />

      {/* terms */}
      <Terms />

      {/* policy */}
      <Policy />

      {/* testimonial */}
      <Testimonial />

      {/* footer */}
      <Footer />
    </React.Fragment>
  );
};

export default withRouter(Home);


